export const COLUMNS_ACCESSORS = {
	VALIDATED_BY: 'validated_by',
	CUSTOMER: 'customer',
	ACTIONS: 'actions',
	COMPANY: 'company',
	REASON: 'reason',
	AMOUNT: 'amount',
	STATUS: 'status',
	TOPIC: 'topic',
	ID: 'id',
};

export const columns = [
	{
		Header: 'common.fields.id',
		accessor: COLUMNS_ACCESSORS.ID,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.reason',
		accessor: COLUMNS_ACCESSORS.REASON,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.amount',
		accessor: COLUMNS_ACCESSORS.AMOUNT,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.customer',
		accessor: COLUMNS_ACCESSORS.CUSTOMER,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.company',
		accessor: COLUMNS_ACCESSORS.COMPANY,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.validated_by',
		accessor: COLUMNS_ACCESSORS.VALIDATED_BY,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.comp_status',
		accessor: COLUMNS_ACCESSORS.STATUS,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];
