import React from 'react';
import { Box, Button, Divider, Popover } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { bool, func, node, object, string } from 'prop-types';
import HistoryIcon from '@material-ui/icons/History';

// Import components
import { IconButton } from 'components/elements/atoms';

// Import utils
import { useTranslations } from 'components/utilities';
import { usePreviewPopup } from './usePreviewPopup';

// Import styles
import { popoverStyle, useStyles } from './PreviewPopup.styles';

// Import assets
import {
	CheckMarkDoneIcon,
	CircleCloseIcon,
	DotsMenuIcon,
	DownloadDocumentIcon,
	EditPenOutlinedIcon,
	PrintIcon,
	StarOutlinedIcon,
	WalletIcon,
} from 'assets/icons';

export const PreviewPopup = ({
	cell,
	toggleBookingHistory,
	toggleTransactionsHistory,
	toggleSubscriptionsHistory,
	showSubscriptionsHistory = false,
	showBookingStatusActions = true,
	showTransactionsHistory = true,
	showPrintInvoice = false,
	showAddRating = true,
	showTransferBooking = true,
	showBookingHistory = true,
	customActions,
	rowUuid = '',
}) => {
	const { t } = useTranslations();
	const classes = useStyles();

	const {
		handleApproveCancellation,
		handleOnInvoicePrint,
		handleAccept,
		handleCancel,
		canApproveCancellation,
		canCancel,
		canAccept,
		goToTransferBookings,
		invoicePrintLoading,
		goToRatingsTab,
		isDivider,
	} = usePreviewPopup(cell, rowUuid);

	return (
		<PopupState variant="popover" popupId="demo-popup-menu">
			{(popupState) => (
				<Box className={classes.container}>
					<IconButton
						className={classes.dotsButton}
						{...bindTrigger(popupState)}
					>
						<DotsMenuIcon className={classes.mainIcon} />
					</IconButton>
					<Popover
						PaperProps={{
							style: popoverStyle,
							elevation: 1,
						}}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: -10,
							horizontal: 'right',
						}}
						{...bindPopover(popupState)}
					>
						<Box className={classes.popoverContent}>
							{customActions ? (
								customActions
							) : (
								<>
									{showBookingStatusActions && (
										<>
											{canAccept && (
												<Button
													className={classes.reAcceptButton}
													fullWidth
													onClick={handleAccept}
													startIcon={
														<CheckMarkDoneIcon
															className={classes.reAcceptIcon}
														/>
													}
												>
													{t('common.buttons.re_accept_booking')}
												</Button>
											)}
											{canApproveCancellation && (
												<Button
													className={classes.confirmRejectionButton}
													fullWidth
													onClick={handleApproveCancellation}
													startIcon={
														<CircleCloseIcon
															className={classes.confirmRejectionIcon}
														/>
													}
												>
													{t('common.buttons.confirm_rejection')}
												</Button>
											)}
											{canCancel && (
												<Button
													className={classes.confirmRejectionButton}
													fullWidth
													onClick={handleCancel}
													startIcon={
														<CircleCloseIcon
															className={classes.confirmRejectionIcon}
														/>
													}
												>
													{t('common.buttons.cancel_booking')}
												</Button>
											)}
											{isDivider && <Divider className={classes.divider} />}
										</>
									)}
									{showTransferBooking && (
										<>
											<Button
												className={classes.button}
												fullWidth
												startIcon={
													<EditPenOutlinedIcon className={classes.icon} />
												}
												onClick={goToTransferBookings}
											>
												{t('common.buttons.transfer_booking')}
											</Button>
											<Divider className={classes.divider} />{' '}
										</>
									)}
									{showBookingHistory && (
										<Button
											className={classes.button}
											fullWidth
											startIcon={<HistoryIcon className={classes.icon} />}
											onClick={toggleBookingHistory}
										>
											{t('common.buttons.booking_history')}
										</Button>
									)}
									{showSubscriptionsHistory && (
										<Button
											className={classes.button}
											fullWidth
											startIcon={<WalletIcon className={classes.icon} />}
											onClick={toggleSubscriptionsHistory}
										>
											{t('common.buttons.subscription_history')}
										</Button>
									)}
									{showTransactionsHistory && (
										<Button
											className={classes.button}
											fullWidth
											startIcon={<WalletIcon className={classes.icon} />}
											onClick={toggleTransactionsHistory}
										>
											{t('common.buttons.transactions_history')}
										</Button>
									)}
									{showAddRating && (
										<Button
											className={classes.button}
											fullWidth
											startIcon={<StarOutlinedIcon className={classes.icon} />}
											onClick={goToRatingsTab}
										>
											{t('common.buttons.add_rating')}
										</Button>
									)}
									<Divider className={classes.divider} />
									{showPrintInvoice && (
										<>
											<Button
												className={classes.button}
												fullWidth
												startIcon={<PrintIcon className={classes.icon} />}
												disabled={invoicePrintLoading}
												onClick={handleOnInvoicePrint}
											>
												{t('common.buttons.print_invoice')}
											</Button>
											<Divider className={classes.divider} />
										</>
									)}
									<Button
										className={classes.button}
										fullWidth
										startIcon={
											<DownloadDocumentIcon className={classes.icon} />
										}
										disabled={invoicePrintLoading}
										onClick={handleOnInvoicePrint}
									>
										{t('common.buttons.download_invoice')}
									</Button>
								</>
							)}
						</Box>
					</Popover>
				</Box>
			)}
		</PopupState>
	);
};

PreviewPopup.propTypes = {
	toggleSubscriptionsHistory: func,
	toggleTransactionsHistory: func,
	toggleBookingHistory: func,
	hideBookingStatusActions: bool,
	showBookingStatusActions: bool,
	showSubscriptionsHistory: bool,
	showTransactionsHistory: bool,
	showTransferBooking: bool,
	showBookingHistory: bool,
	showPrintInvoice: bool,
	showAddRating: bool,
	customActions: node,
	rowUuid: string,
	cell: object,
};
