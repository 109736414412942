export const INITIAL_VALUES = {
	details: {
		name: {
			ar: '',
			en: '',
		},
		description: {
			ar: '',
			en: '',
		},
	},
	order: null,
	is_active: true,
};
