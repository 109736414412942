import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	disabledIcon: {
		color: theme.palette.gray.main,
	},
	icon: {
		color: theme.palette.text[1],
	},
	errorIcon: {
		color: theme.palette.error.main,
	},
	container: {
		position: 'relative',
	},
	placeholder: {
		position: 'absolute',
		top: '55px',
		color: theme.palette.text[1],
		padding: '0px 12px',
	},
}));
