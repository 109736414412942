import React from 'react';
import { fetchUrgentBookings } from 'store/actions';

// Import columns
import { COLUMNS_ACCESSORS, getColumns } from './columns';

// Import components
import { Filters, TableBodyRow } from './components';
import { InnerNavbarElementsButtons } from 'components/elements';

// Import utilities and helpers
import { TableRPC } from 'components/utilities';
import { buttons } from './helpers';

export const UrgentBookings = () => {
	return (
		<TableRPC
			columnsAccessor={COLUMNS_ACCESSORS}
			columns={getColumns(true)}
			tableBodyRow={TableBodyRow}
			fetchDataTableAction={fetchUrgentBookings}
			title="nav.operations.urgent_bookings"
			filtersBar={<Filters />}
			tableNavigation={
				<InnerNavbarElementsButtons
					filterPath="urgent_assistance_status"
					buttons={buttons}
				/>
			}
		/>
	);
};
