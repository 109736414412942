import React from 'react';
import { bool, object } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import utils and helpers
import { COLUMNS_ACCESSORS } from '../../../../columns';
import { replaceOfficeDetailsUrl } from 'helpers';
import { actionMenuFields, cancelStatusList } from './helpers';

// Import components
import {
	CompensationAmountTableCell,
	NewActionsMenuTableCell,
	BookingIdTableCell,
	CompanyTableCell,
	LinkTableCell,
	Notes,
	CommonStatusTableCell,
	CancelReasonTableCell,
} from 'components/elements';

const {
	COMPENSATION_AMOUNT,
	OFFICE_NAME,
	CREATED_AT,
	PICK_DATE,
	CUSTOMER,
	COMPANY,
	ACTIONS,
	STATUS,
	REASON,
	ID,
} = COLUMNS_ACCESSORS;

export const Cell = ({ isHovered, cell }) => {
	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		const {
			cancel_reason_status: cancelReasonStatus,
			customer_phone: customerPhone,
			customer_name: customerName,
			office_uuid: officeUuid,
			office_name: officeName,
		} = cell.row.original;

		switch (cell.column.id) {
			case ID:
				return (
					<BookingIdTableCell
						key={cell.column.id}
						notes={<Notes cell={cell} />}
						isHovered={isHovered}
						cell={cell}
					/>
				);

			case COMPENSATION_AMOUNT:
				return <CompensationAmountTableCell key={cell.column.id} cell={cell} />;

			case COMPANY:
				return <CompanyTableCell key={cell.column.id} cell={cell} />;

			case OFFICE_NAME:
				return (
					<LinkTableCell
						key={cell.column.id}
						cell={cell}
						to={replaceOfficeDetailsUrl(officeUuid)}
					>
						{officeName}
					</LinkTableCell>
				);

			case CUSTOMER:
				return (
					<TableCell key={cell.column.id}>
						{customerName}
						<br /> {customerPhone}
					</TableCell>
				);

			case PICK_DATE:
				return (
					<TableCell key={cell.column.id}>
						{cell.row.original.pick_date}
						<br /> {cell.row.original.pick_time}
					</TableCell>
				);

			case CREATED_AT: {
				const [date, time] = cell.row.original?.created_at.split(' ');
				return (
					<TableCell key={cell.column.id}>
						{date}
						<br />
						{time}
					</TableCell>
				);
			}

			case REASON:
				return <CancelReasonTableCell key={cell.column.id} cell={cell} />;

			case STATUS:
				return (
					<CommonStatusTableCell
						currentStatus={cancelReasonStatus}
						statusList={cancelStatusList}
						key={cell.column.id}
						cell={cell}
					/>
				);

			case ACTIONS:
				return (
					<NewActionsMenuTableCell
						previewPopupFields={actionMenuFields}
						key={cell.column.id}
						cell={cell}
						extraActions={{ validation: true }}
						isHovered={isHovered}
					/>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return renderCell(cell);
};

Cell.propTypes = {
	cell: object.isRequired,
	isHovered: bool,
};
