import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
	black,
	white,
	primary: {
		contrastText: white,
		dark: '#007A9D',
		main: '#15AABE',
		light: '#DFF2F1',
		medium: '#E9F7F7',
		map: '#00ACA1',
	},
	secondary: {
		contrastText: white,
		dark: colors.blue[900],
		main: colors.blue['A400'],
		light: colors.blue['A400'],
	},
	tertiary: {
		contrastText: white,
		dark: '#FF754C',
		middle: '#FF9A7B',
		main: '#FFCE73',
		light: '#FFF3DC',
	},
	success: {
		contrastText: white,
		dark: colors.green[900],
		main: '#359C6F',
		light: '#E4F6EE',
		lighter: '#DBFDEA',
		medium: '#14AE5C',
	},
	info: {
		contrastText: white,
		dark: colors.blue[900],
		main: '#0700fa',
		light: '#f6f8fe',
	},
	warning: {
		contrastText: white,
		dark: colors.orange[900],
		main: '#F2994A',
		light: 'rgba(242, 153, 74, 0.1)',
		medium: '#FDEED7',
		mid: '#DF5F17',
		1: '#EE7921',
	},
	error: {
		contrastText: white,
		dark: colors.red[900],
		main: '#FF2B2B',
		light: '#FEF6F6',
		medium: '#CC2222',
		lighter: '#FEE2E2',
		border: '#FECACA',
		darkBorder: '#FCA5A5',
	},
	text: {
		primary: colors.blueGrey[900],
		secondary: colors.blueGrey[600],
		link: colors.blue[600],
		light: '#c6c7c7',
		inputText: '#11142D',
		inputLabel: '#263238',
		1: '#717680',
		2: '#555F6A',
		3: '#808191',
		4: '#263238',
		5: '#878787',
	},
	background: {
		default: '#F4F6F8',
		paper: white,
		1: '#C1C7D0',
		2: '#F4F8FD',
		3: '#DCDFE3',
		4: '#E3E6EC',
		5: '#E8EBED',
		6: '#F3F4F5',
		7: '#D5D9DE',
	},
	gray: {
		main: '#8A94A6',
		medium: '#959FAA',
		light: '#F3F3F3',
		dark: '#808080',
		label: '#959FAA',
		field: '#f7f7f7',
		labelLight: '#7D8997',
		icon: '#A1A1A1',
		border: '#0000000f',
		input: '#F7F7F7',
	},
	purple: {
		main: '#763F98',
		light: '#F3EDFA',
		medium: '#D6BCEB',
	},
	uncategorized: {
		pink: {
			1: '#D52D73',
			2: '#FAEBF3',
		},
		blue: {
			1: '#1F61F1',
		},
	},
	starIcon: '#FFCD74',
	blueChip: '#A6C4E0',
	icon: colors.blueGrey[600],
	divider: colors.grey[200],
};
