import { TextFieldAdapter, SelectFieldAdapter } from 'components/elements';

export const getRatingData = ({ section, prices }) => {
	switch (section) {
		case 'carInfo.prices.daily':
			return {
				daysFrom: prices.daily.from,
				daysTo: prices.daily.to,
				averagePrice: prices.daily.average_nett_price,
				popularPrice: prices.daily.popular_nett_price,
			};
		case 'carInfo.prices.weekly':
			return {
				daysFrom: prices.weekly.from,
				daysTo: prices.weekly.to,
				averagePrice: prices.weekly.average_nett_price,
				popularPrice: prices.weekly.popular_nett_price,
			};
		case 'carInfo.prices.monthly':
			return {
				daysFrom: prices.monthly.from,
				daysTo: prices.monthly.to,
				averagePrice: prices.monthly.average_nett_price,
				popularPrice: prices.monthly.popular_nett_price,
			};
		case 'carInfo.prices.annual':
			return {
				daysFrom: prices.annual.from,
				daysTo: prices.annual.to,
				averagePrice: prices.annual.average_nett_price,
				popularPrice: prices.annual.popular_nett_price,
			};
		default:
			break;
	}
};

export const RATING_SECTIONS = (section) => [
	{
		title: 'cars.form.ratings.daily_rate',
		section: `carInfo.${section}.daily`,
	},
	{
		title: 'cars.form.ratings.weekly_rate',
		section: `carInfo.${section}.weekly`,
	},
	{
		title: 'cars.form.ratings.monthly_rate',
		section: `carInfo.${section}.monthly`,
	},
];

export const RATING_FIELDS = ({ section, average, popular, isAdmin }) => {
	return [
		{
			component: SelectFieldAdapter,
			type: 'number',
			name: `${section}.days`,
			md: 3,
			isField: true,
			isDays: true,
		},
		{
			component: TextFieldAdapter,
			type: 'number',
			label: 'cars.form.ratings.price',
			name: `${section}.nett_price`,
			md: 2,
			isField: true,
		},
		{
			component: TextFieldAdapter,
			type: 'number',
			label: 'cars.form.ratings.discount_price',
			name: `${section}.discount_nett_price`,
			md: 3,
			isField: true,
		},
		{
			component: TextFieldAdapter,
			type: 'number',
			label: 'cars.form.ratings.nett_price_before_markup',
			name: `${section}.nett_price_before_markup`,
			md: 3,
			isField: true,
			disabled: !isAdmin,
		},
		{
			component: TextFieldAdapter,
			type: 'number',
			label: 'cars.form.ratings.nett_price_after_markup',
			name: `${section}.nett_price_after_markup`,
			md: 3,
			isField: true,
			disabled: !isAdmin,
		},
		{
			title: 'cars.form.ratings.average_price',
			price: `${average}`,
			md: 2,
			isField: false,
		},
		{
			title: 'cars.form.ratings.popular_price',
			price: popular,
			md: 2,
			isField: false,
		},
	];
};
