import {
	TextFieldAdapter,
	SelectFieldAdapter,
	MultiselectFiledAdapter,
	DatePickerFieldAdapter,
} from 'components/elements';
import { MenuItem } from '@material-ui/core';
import React from 'react';

import AllCarsButton from '../AllCarsButton';

const findCarLabel = (companyCars) => (uuid) => {
	const foundCar = companyCars?.find((car) => car.vehicle_uuid === uuid);
	return foundCar ? foundCar.car_full_name : '';
};

export const DETAILS_FIELDS = ({
	isLoaded,
	companyList,
	isCompanyCarsLoading,
	companyCars,
	classes,
}) => [
	{
		type: 'select',
		name: 'company_uuid',
		component: SelectFieldAdapter,
		label: 'special_offer.form.details.company_name',
		md: 6,
		xs: 12,
		children:
			isLoaded &&
			companyList.map(({ name, uuid }) => (
				<MenuItem key={name.en} value={uuid}>
					{name.en}
				</MenuItem>
			)),
	},
	{
		type: 'number',
		name: 'position',
		component: TextFieldAdapter,
		label: 'special_offer.form.details.position',
		md: 6,
		xs: 12,
	},
	{
		type: 'text',
		name: 'label_en',
		component: TextFieldAdapter,
		label: 'special_offer.form.details.label_en',
		md: 6,
		xs: 12,
	},
	{
		type: 'text',
		name: 'label_ar',
		component: TextFieldAdapter,
		label: 'special_offer.form.details.label_ar',
		md: 6,
		xs: 12,
	},
	{
		type: 'date',
		name: 'date_from',
		component: DatePickerFieldAdapter,
		label: 'special_offer.form.details.date_from',
		md: 6,
		xs: 12,
	},
	{
		type: 'date',
		name: 'date_to',
		component: DatePickerFieldAdapter,
		label: 'special_offer.form.details.date_to',
		md: 6,
		xs: 12,
	},
	{
		type: 'select',
		name: 'vehicle_uuids',
		component: MultiselectFiledAdapter,
		label: 'special_offer.form.details.cars',
		md: 6,
		xs: 12,
		labelExtractor: findCarLabel(companyCars),
		children:
			!isCompanyCarsLoading &&
			companyCars.map(({ car_full_name, vehicle_uuid }) => (
				<MenuItem key={car_full_name} value={vehicle_uuid}>
					{car_full_name}
				</MenuItem>
			)),
	},
	{
		type: 'select',
		name: 'button',
		label: '',
		md: 6,
		xs: 12,
		// eslint-disable-next-line react/display-name
		children: () => (
			<AllCarsButton classes={classes} companyCars={companyCars} />
		),
	},
];
