import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	label: ({ error }) => ({
		color: error ? theme.palette.error.main : theme.palette.text.inputLabel,
		fontWeight: 600,
		fontSize: '12px',
	}),
	error: {
		color: theme.palette.error.main,
		fontWeight: 400,
		fontSize: '11px',
		marginLeft: '14px',
	},
	icon: {
		width: '24px',
		height: '24px',
	},
	extraLabel: {
		color: theme.palette.text[5],
	},
}));
