import React from 'react';
import { Grid } from '@material-ui/core';
import { submitEditCarForm } from 'store/actions';

// Import helpers
import { INITIAL_VALUES, validation } from './helpers';
import { URLS } from 'components/routes';

// Import utilities
import { FormRPC } from 'components/utilities';
import { useGlobalCarForm } from '../useGlobalCarForm';

// Import components
import {
	CarDetails,
	Ratings,
	CarSpecification,
	Details,
	ExtraServices,
	SubscriptionModel,
} from '../components';

//Import styles
import useStyles from '../styles';

const GlobalAddCar = () => {
	const { handleFetchCarInfo } = useGlobalCarForm();

	const classes = useStyles();

	return (
		<div className={classes.root}>
			<FormRPC
				fetchFormResAction={handleFetchCarInfo}
				submitFormAction={submitEditCarForm}
				isCardLayout={false}
				goBackPath={URLS.officesUrl}
				store={(store) => store.car.form}
				validation={validation}
				initialValues={INITIAL_VALUES}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item lg={9} xs={12}>
							<CarDetails section="details" isGlobalAdd />
						</Grid>
						<Grid item lg={9} xs={12}>
							<Ratings section="prices" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<SubscriptionModel section="subscription" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<ExtraServices section="options" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<CarSpecification section="specification" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<Details section="details" />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</div>
	);
};

export default GlobalAddCar;
