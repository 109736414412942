import React from 'react';

// Import helpers and utils
import { columns, COLUMNS_ACCESSORS } from './columns';
import { TableRPC } from 'components/utilities';
import { BOOKINGS_PAGES_TYPES } from 'helpers';

// Import components
import { AdditionalInfo, Export, Filters, TableBodyRow } from '../components';
import { InnerNavbarElements } from 'components/elements';

// Import store
import { fetchBookings } from 'store/actions';

export const BookingsList = () => (
	<TableRPC
		columnsAccessor={COLUMNS_ACCESSORS}
		columns={columns}
		tableBodyRow={TableBodyRow}
		fetchDataTableAction={fetchBookings}
		title="nav.bookings_list"
		outerToolbarElements={<Export pageType={BOOKINGS_PAGES_TYPES.MAIN} />}
		filtersBar={<Filters />}
		tableNavigation={<InnerNavbarElements />}
		additionalNavigationElements={
			<AdditionalInfo
				showAssignedDriverAdditional
				showDeliveryStatusAdditional
			/>
		}
	/>
);
