import React from 'react';
import { bool, func } from 'prop-types';
import {
	DialogActions,
	DialogContent,
	Button,
	Dialog,
	Grid,
} from '@material-ui/core';

// Import utilities
import { useValidationModal } from './useValidationModal';
import { useTranslations } from 'components/utilities';

// Import components
import {
	ValidateCancellationForm,
	ValidateCompensationForm,
	ValidateUrgentForm,
} from '..';
import { ClosableDialogTitle } from 'components/elements';

export const ValidationModal = ({ open, onClose }) => {
	const { t } = useTranslations();

	const { isCancellation, isCompensation, isUrgent } = useValidationModal();

	return (
		<Dialog fullWidth disableEscapeKeyDown open={open} onClose={onClose}>
			<ClosableDialogTitle
				title={t('validation.table.modal.title')}
				onClose={onClose}
			/>
			<DialogContent>
				<Grid container spacing={2}>
					{isCompensation && (
						<Grid item xs={12}>
							<ValidateCompensationForm />
						</Grid>
					)}
					{isUrgent && (
						<Grid item xs={12}>
							<ValidateUrgentForm />
						</Grid>
					)}
					{isCancellation && (
						<Grid item xs={12}>
							<ValidateCancellationForm />
						</Grid>
					)}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button color="primary" variant="outlined" onClick={onClose}>
					{t('common.buttons.ok')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

ValidationModal.propTypes = {
	onClose: func.isRequired,
	open: bool.isRequired,
};
