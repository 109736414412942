import React from 'react';
import { Grid } from '@material-ui/core';
import { string } from 'prop-types';

// Import utils
import { useTranslations } from 'components/utilities';
import { useImport } from './useImport';

// Import components
import { ButtonProgress } from 'components/elements';

export const Import = ({ importDataUrl }) => {
	const {
		handleOnInputChange,
		handleOnButtonClick,
		hiddenFileInput,
		isLoading,
	} = useImport(importDataUrl);

	const { t } = useTranslations();

	return (
		<Grid item>
			<ButtonProgress
				onClick={handleOnButtonClick}
				isLoading={isLoading}
				disabled={isLoading}
				variant="outlined"
				color="primary"
			>
				{t('common.buttons.import')}
			</ButtonProgress>
			<input
				onChange={handleOnInputChange}
				style={{ display: 'none' }}
				ref={hiddenFileInput}
				type="file"
			/>
		</Grid>
	);
};

Import.propTypes = {
	importDataUrl: string.isRequired,
};
