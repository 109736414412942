import React, { forwardRef } from 'react';
import { bool, func, object, oneOf, oneOfType, string } from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import clsx from 'clsx';

//Import styles
import { useStyles } from './Button.styles';

// Import utils and helpers
import { useTranslations } from 'components/utilities';

/**
 * @typedef {object} ButtonProps
 *
 * @property {("outlined_and_primary" | "outlined_and_dark" | "primary_filled" | "white_filled" | "primary_text" | "dark_text" | "error")} type
 * @property {("tiny" | "small" | "medium" | "large")} size
 * @property {("reset" | "submit" | "button")} buttonType
 * @property {boolean} isLoading
 * @property {function} icon
 * @property {string} text
 */

/**
 * @type {React.ForwardRefRenderFunction<HTMLButtonElement, ButtonProps>}
 */

export const Button = forwardRef(
	(
		{
			type = 'primary_filled',
			buttonType = 'button',
			isLoading = false,
			size = 'medium',
			icon: Icon,
			text,
			...rest
		},
		ref
	) => {
		const { t } = useTranslations();
		const classes = useStyles({ type });

		return (
			<MuiButton
				className={clsx([classes.button, classes[size], classes[type]])}
				startIcon={
					Icon && <Icon color="inherit" className={classes[`${size}_icon`]} />
				}
				type={buttonType}
				ref={ref}
				{...rest}
			>
				{isLoading && (
					<CircularProgress
						classes={{ indeterminate: classes.circularProgressIndeterminate }}
						className={classes.circularProgress}
						size={16}
					/>
				)}
				{t(text)}
			</MuiButton>
		);
	}
);

Button.displayName = 'Button';
Button.propTypes = {
	buttonType: oneOf(['submit', 'reset', 'button']),
	size: oneOf(['tiny', 'small', 'medium', 'large']),
	type: oneOf([
		'outlined_and_primary',
		'outlined_and_dark',
		'primary_filled',
		'white_filled',
		'primary_text',
		'dark_text',
		'error',
	]),
	isLoading: bool,
	text: string,
	icon: oneOfType([func, object]),
};
