import React from 'react';

// Import components
import {
	CancellationStatusFilter,
	MultiCompaniesFilter,
	BaseYesNoAllFilter,
	DateRangeFilter,
	Grid,
} from 'components/elements';

// Import utils
import { useProfile } from 'components/utilities';

export const FilterFields = () => {
	const { isAdmin } = useProfile();

	return (
		<Grid container spacing={3}>
			{isAdmin && (
				<MultiCompaniesFilter
					name="companies"
					label="bookings.filters.company"
					xs={12}
					md={12}
				/>
			)}
			<BaseYesNoAllFilter
				name="is_compensation"
				label="bookings.filters.compensation"
				xs={12}
				md={6}
			/>
			<CancellationStatusFilter md={6} xs={12} />
			<DateRangeFilter
				nameFrom="pick_date"
				nameUntil="pick_date_to"
				label="bookings.filters.pick_date"
				combinedInputs
			/>
			<DateRangeFilter
				nameFrom="drop_date"
				nameUntil="drop_date_to"
				label="bookings.filters.drop_date"
				combinedInputs
			/>
		</Grid>
	);
};
