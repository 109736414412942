import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { QUERY_KEYS } from 'helpers';

const useCompanyCars = (company_uuid) => {
	const path = `/v2/special-offer/search-cars`;

	const { data, isError, isFetching } = useQuery(
		QUERY_KEYS.COMPANIES.SPECIAL_OFFERS_CARS(company_uuid),
		async () => await services.post(path, { company_uuid }),
		{
			refetchOnWindowFocus: false,
			enabled: !!company_uuid,
		}
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError };
};

export default useCompanyCars;
