import React from 'react';
import { any, bool, func, object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';

// Import helpers
import { replaceOfficeDetailsUrl } from 'helpers';

// Import components
import {
	LinkTableCell,
	BookingIdTableCell,
	Notes,
	CompanyTableCell,
	RatingTableCell,
	NewActionsMenuTableCell,
} from 'components/elements';

// Import styles
import { useSharedStyles } from 'theme/styles/shared';
import { actionMenuFields } from './helpers';

export const TableBodyRow = ({
	columnsAccessor,
	onMouseLeave,
	onMouseEnter,
	isHovered,
	row,
	ref,
}) => {
	const classes = useSharedStyles();

	const {
		ID,
		CUSTOMER,
		COMPANY,
		OFFICE_RATING,
		PICK_DATE,
		OFFICE_NAME,
		ACTIONS,
	} = columnsAccessor;

	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		const {
			customer_phone: customerPhone,
			customer_name: customerName,
			pick_date: pickDate,
			pick_time: pickTime,
			office_rating: officeRating,
			office_name: officeName,
			office_uuid: officeUuid,
		} = cell.row.original;

		switch (cell.column.id) {
			case ID:
				return (
					<BookingIdTableCell
						key={cell.column.id}
						notes={<Notes cell={cell} />}
						isHovered={isHovered}
						cell={cell}
					/>
				);

			case CUSTOMER:
				return (
					<TableCell key={cell.column.id}>
						{customerName}
						<br /> {customerPhone}
					</TableCell>
				);

			case COMPANY:
				return <CompanyTableCell key={cell.column.id} cell={cell} />;

			case OFFICE_NAME:
				return (
					<LinkTableCell
						key={cell.column.id}
						cell={cell}
						to={replaceOfficeDetailsUrl(officeUuid)}
					>
						{officeName}
					</LinkTableCell>
				);

			case PICK_DATE:
				return (
					<TableCell key={cell.column.id}>
						{pickDate}, {pickTime}
					</TableCell>
				);

			case OFFICE_RATING:
				return (
					<RatingTableCell
						key={cell.column.id}
						cell={cell}
						rating={officeRating}
						emptyValue=""
					/>
				);

			case ACTIONS:
				return (
					<NewActionsMenuTableCell
						key={cell.column.id}
						cell={cell}
						isHovered={isHovered}
						extraActions={{ compensation: true }}
						previewPopupFields={actionMenuFields}
					/>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			key={row.id}
			className={isHovered ? classes.hoverRow : ''}
			ref={ref}
			{...row.getRowProps()}
		>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
	columnsAccessor: object.isRequired,
	onMouseLeave: func,
	onMouseEnter: func,
	isHovered: bool,
	ref: any,
};
