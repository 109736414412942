export const COLUMNS_ACCESSORS = {
	ID: 'id',
	STATUS: 'status',
	OFFICE_NAME: 'office_name',
	DURATION: 'response_time',
	APPROVED: 'approved',
	COMPENSATED: 'compensated',
	RATING_REASON: 'rating_reason',
	SETTLEMENT_STATUS: 'settlement_status',
	CUSTOMER_NAME: 'customer_name',
	PICK_DATE: 'pick_date',
	CREATED_AT: 'created_at',
	ACTIONS: 'actions',
	DROP_DATE: 'drop_date',
	ASSIGNED_DRIVER: 'assigned_driver',
	DELIVERY_STATUS: 'delivery_status',
	COMPANY: 'company',
	PERIOD: 'period',
	EXTENDED_AT: 'extended_at',
	PAYMENT_METHOD: 'payment_method',
};

export const columns = [
	{
		Header: 'common.fields.id',
		accessor: COLUMNS_ACCESSORS.ID,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'common.fields.customer',
		accessor: COLUMNS_ACCESSORS.CUSTOMER_NAME,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'common.fields.customer_type',
		accessor: 'customer_type',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'common.fields.company',
		accessor: COLUMNS_ACCESSORS.COMPANY,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.office_city',
		accessor: 'office_city',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.period',
		accessor: COLUMNS_ACCESSORS.PERIOD,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.created_at',
		accessor: COLUMNS_ACCESSORS.CREATED_AT,
		disableSortBy: false,
	},
	{
		Header: 'bookings.table.headers.status',
		accessor: COLUMNS_ACCESSORS.STATUS,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},

	// Additional
	{
		Header: 'bookings.table.headers.customer_nationality',
		accessor: 'customer_nationality',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.gender',
		accessor: 'gender',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.pick_location',
		accessor: 'pick_location',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.drop_location',
		accessor: 'drop_location',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.payment_method',
		accessor: COLUMNS_ACCESSORS.PAYMENT_METHOD,
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.total_base_nett_price',
		accessor: 'total_base_nett_price',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.total_discounted_gross_price',
		accessor: 'total_discounted_gross_price',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.vehicle',
		accessor: 'vehicle',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.booking_no',
		accessor: 'customer_no_booking',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.booking_time',
		accessor: 'respond_time',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.duration',
		accessor: COLUMNS_ACCESSORS.DURATION,
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.updated_time',
		accessor: 'updated_at',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.approved',
		accessor: COLUMNS_ACCESSORS.APPROVED,
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.admin_comment',
		accessor: 'admin_comment',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.license_number',
		accessor: 'license_number',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.compensated',
		accessor: COLUMNS_ACCESSORS.COMPENSATED,
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.office_rating',
		accessor: 'office_rating',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.rating_reason',
		accessor: COLUMNS_ACCESSORS.RATING_REASON,
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.customer_email',
		accessor: 'customer_email',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.office_address',
		accessor: 'office_address',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.settlement_status',
		accessor: COLUMNS_ACCESSORS.SETTLEMENT_STATUS,
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.assigned_driver',
		accessor: COLUMNS_ACCESSORS.ASSIGNED_DRIVER,
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.delivery_status',
		accessor: COLUMNS_ACCESSORS.DELIVERY_STATUS,
		disableSortBy: true,
		isHidden: true,
	},
];
