import { useLocation } from 'react-router-dom';
import { useFormState } from 'react-final-form';

// Import helpers
import { FIELD_TYPES, generateArray, NONE_VALUE } from 'helpers';

// Import utils
import { useTranslations } from 'components/utilities';
import { useCompanies } from 'queries';

export const useFormContent = () => {
	const {
		t,
		i18n: { language },
	} = useTranslations();
	const { pathname } = useLocation();
	const { data: companies, isFetching } = useCompanies(true);

	const { values } = useFormState();

	const isAddView = pathname.includes('add');
	const prevBookingNumbers = generateArray(0, 20).map((number) => ({
		label: number,
		value: number,
	}));

	const selectOptions = [
		{ label: t('common.none'), value: NONE_VALUE },
		...prevBookingNumbers,
	];

	const multiSelectLabelExtractor = (options, currentUuid) =>
		currentUuid === 'all'
			? null
			: options.find(({ uuid }) => uuid === currentUuid)?.name[language];

	const cards = [
		{
			title: 'promo_code',
			fields: [
				{
					type: FIELD_TYPES.TEXT,
					name: 'details.code',
					label: 'promo_codes.form.promo_code.code',
					required: isAddView,
					disabled: !isAddView,
				},
			],
		},
		{
			title: 'discount',
			fields: [
				{
					type: FIELD_TYPES.NUMBER,
					name: 'discount.discount',
					label: 'promo_codes.form.discount.discount',
					md: 6,
				},
				{
					type: FIELD_TYPES.NUMBER,
					name: 'discount.max_discount',
					label: 'promo_codes.form.discount.max_discount',
					md: 6,
				},
			],
		},
		{
			title: 'period',
			fields: [
				{
					type: FIELD_TYPES.DATE,
					name: 'period.from',
					label: 'promo_codes.form.period.from',
					md: 6,
					minDate: false,
				},
				{
					type: FIELD_TYPES.DATE,
					name: 'period.until',
					label: 'promo_codes.form.period.until',
					md: 6,
					minDate: false,
				},
			],
		},
		{
			title: 'maximum_usage',
			fields: [
				{
					type: FIELD_TYPES.NUMBER,
					name: 'max_usage',
					label: 'promo_codes.form.maximum_usage.max_usage',
					md: 6,
				},
				{
					type: FIELD_TYPES.MULTI_SELECT,
					name: 'rental_companies',
					label: 'promo_codes.form.maximum_usage.rental_company',
					md: 6,
					currentMultiSelectValues: values?.rental_companies,
					disabled: isFetching,
					options: companies,
					showAllOption: true,
					keyExtractor: ({ uuid }) => uuid,
					labelExtractor: ({ name }) => name[language],
					multiSelectLabelExtractor,
				},
				{
					type: FIELD_TYPES.SELECT,
					name: 'conditions.allowed_previous_bookings_number',
					label: 'promo_codes.form.maximum_usage.number_of_previous_booking',
					md: 6,
					options: selectOptions,
					keyExtractor: ({ value }) => value,
					labelExtractor: ({ label }) => label,
				},
				{
					type: FIELD_TYPES.NUMBER,
					name: 'budget.max',
					label: 'promo_codes.form.maximum_usage.max_budget',
					md: 6,
				},
				{
					type: FIELD_TYPES.CHECKBOX,
					name: 'conditions.one_time_use',
					label: 'promo_codes.form.maximum_usage.one_time_use',
					md: 12,
				},
				{
					type: FIELD_TYPES.CHECKBOX,
					name: 'conditions.only_for_delivery',
					label: 'promo_codes.form.maximum_usage.only_for_delivery',
					md: 12,
				},
				{
					type: FIELD_TYPES.CHECKBOX,
					name: 'conditions.is_subscription',
					label: 'promo_codes.form.maximum_usage.subscription',
					md: 12,
				},
			],
		},
	];

	return {
		cards,
	};
};
