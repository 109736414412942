import services from 'services/services';
import { fetchCancellationValidationBookingsUrl } from 'store/paths';

export const fetchCancellationValidationBookings =
	({ options, queryParams, cancelToken }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			const defaultQueryParams = '?columns[8][search][value]=review';

			// Get response
			const { data } = await services.post(
				fetchCancellationValidationBookingsUrl(
					queryParams || defaultQueryParams
				),
				body,
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};
