import { useFormState } from 'react-final-form';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useAirportList } from 'queries';

// Import helpers
import { EN } from 'helpers';

const useTerminalField = () => {
	const { i18n } = useTranslations();
	const isEnLng = i18n.language === EN;

	const {
		values: {
			location: {
				airport: { terminals },
				is_airport,
				lat,
				lng,
			},
			submitting,
		},
	} = useFormState();

	const { data: airports, isFetching, isError } = useAirportList({ lat, lng });

	const convertTerminalName = (airportName, currentTerminalName) => {
		const bracketIndex = airportName.indexOf('(');
		const cutAirportName = airportName.substring(0, bracketIndex - 1);
		const resultAirportName = bracketIndex >= 0 ? cutAirportName : airportName;

		return resultAirportName + ' - ' + currentTerminalName;
	};

	const getTerminals = (airports) =>
		airports.reduce((acc, airport) => {
			const { en, ar } = airport.name;
			const airportName = isEnLng ? en : ar;
			const terminals = airport.terminals
				.flat()
				.map(({ uuid, name: { en, ar } }) => ({
					uuid,
					name: convertTerminalName(airportName, isEnLng ? en : ar),
				}));
			return [...acc, ...terminals];
		}, []);

	const allTerminals = getTerminals(airports);

	const getTerminalLabel = () => (uuid) => {
		const terminal = allTerminals.find((terminal) => terminal.uuid === uuid);
		return terminal ? terminal.name : '';
	};

	return {
		getTerminalLabel,
		allTerminals,
		terminals,
		submitting,
		isFetching,
		isError,
		is_airport,
	};
};

export default useTerminalField;
