import React from 'react';

// Import store
import { fetchCancellationValidationBookings } from 'store/actions';

// Import helpers
import { columns, COLUMNS_ACCESSORS } from './columns';
import { BUTTONS } from './helpers';

// Import components
import { InnerNavbarElementsButtons } from 'components/elements';
import { Filters, TableBodyRow } from './components';
import { TableRPC } from 'components/utilities';
import { BOOKING_CANCELLATION_STATES } from 'helpers';

export const CancellationValidation = () => (
	<TableRPC
		fetchDataTableAction={fetchCancellationValidationBookings}
		title="nav.operations.cancellation_validation"
		columnsAccessor={COLUMNS_ACCESSORS}
		tableBodyRow={TableBodyRow}
		filtersBar={<Filters />}
		columns={columns}
		tableNavigation={
			<InnerNavbarElementsButtons
				defaultFilterState={BOOKING_CANCELLATION_STATES.REVIEW}
				filterPath="cancel_reason_status"
				buttons={BUTTONS}
			/>
		}
	/>
);
