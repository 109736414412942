import React from 'react';
import { object, func, string, bool } from 'prop-types';
import { TableCell, Button } from '@material-ui/core';
import clsx from 'clsx';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { useActiveTableCell } from './useActiveTableCell';
import { ChipLabel } from 'components/elements';

// Import styles
import useStyles from './styles';

export const ActiveTableCell = ({
	cell,
	activeAction = () => {},
	activeText = 'table.actions.active',
	inactiveText = 'table.actions.inactive',
	isActive = cell.row.values.active,
	activatedAlertMessage = 'table.actions.user_activated',
	deactivatedAlertMessage = 'table.actions.user_deactivated',
	id = cell.row.original.uuid,
	hideActive,
	asLabel = false,
}) => {
	const classes = useStyles({ hideActive });
	const { t } = useTranslations();

	const { onActiveItemClick, text, variant } = useActiveTableCell({
		id,
		isActive,
		activeAction,
		activeText,
		inactiveText,
		activatedAlertMessage,
		deactivatedAlertMessage,
		asLabel,
	});

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			{asLabel ? (
				<ChipLabel
					size="small"
					color={isActive ? 'success' : 'error'}
					className={clsx([classes.button, classes.chip])}
					label={t(text)}
					fullWidth
				/>
			) : (
				<Button
					variant={variant}
					onClick={onActiveItemClick}
					color="primary"
					className={classes.button}
				>
					{t(text)}
				</Button>
			)}
		</TableCell>
	);
};

ActiveTableCell.propTypes = {
	cell: object.isRequired,
	id: string,
	activeAction: func,
	activeText: string,
	inactiveText: string,
	activatedAlertMessage: string,
	deactivatedAlertMessage: string,
	isActive: bool,
	hideActive: bool,
	asLabel: bool,
};
