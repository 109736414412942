export const COLUMN_ACCESSORS = {
	DATE: 'date',
	URGENT_REQUEST_STATUS: 'urgent_request_status',
};

export const URGENT_STATUSES_FIELDS = ({ reason, lang, t, classes }) => {
	const {
		name,
		blamable_user_name: assignedBy,
		created_at: createdAt,
		status,
		marked_by_name: markedBy,
		marked_at: markedAt,
	} = reason || {};

	const assignedByLabel = t('common.fields.assigned_by', {
		name: assignedBy,
	});

	const markedByLabel = t('common.fields.marked_by', {
		name: markedBy,
	});

	const fields = [
		{
			accessor: COLUMN_ACCESSORS.URGENT_REQUEST_STATUS,
			label: name[lang],
			chipLabel: status,
			labelClassName: classes.reasonLabel,
		},
		{
			accessor: COLUMN_ACCESSORS.DATE,
			label: assignedByLabel,
			date: createdAt,
			labelClassName: classes.assignedByLabel,
			key: assignedByLabel,
		},
	];

	if (markedBy) {
		fields.push({
			accessor: COLUMN_ACCESSORS.DATE,
			label: markedByLabel,
			date: markedAt,
			labelClassName: classes.assignedByLabel,
			key: markedByLabel,
		});
	}

	return fields;
};
