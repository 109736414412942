import { BOOKING_URGENT_STATES } from 'helpers';

const { SOLVED, UNSOLVED, REVIEW } = BOOKING_URGENT_STATES;

export const buttons = [
	{
		label: 'common.all',
		status: 'all',
	},
	{
		label: 'common.common_states.review',
		status: REVIEW,
	},
	{
		label: 'common.common_states.solved',
		status: SOLVED,
	},
	{
		label: 'common.common_states.unsolved',
		status: UNSOLVED,
	},
];
