import React from 'react';
import { any, bool, func, object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';

// Import helpers
import { COLUMNS_ACCESSORS } from '../../columns';

// Import components
import {
	ValidatedByTableCell,
	ReasonTableCell,
	StatusTableCell,
} from '../../components';
import {
	CompensationAmountTableCell,
	ActionsMenuTableCell,
	BookingIdTableCell,
	CompanyTableCell,
	Notes,
} from 'components/elements';
import { useSharedStyles } from 'theme/styles';

// Import styles

const { VALIDATED_BY, CUSTOMER, COMPANY, ACTIONS, AMOUNT, REASON, ID, STATUS } =
	COLUMNS_ACCESSORS;

export const TableBodyRow = ({
	onMouseLeave,
	onMouseEnter,
	isHovered,
	row,
	ref,
}) => {
	const classes = useSharedStyles();

	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		const { customer_phone: customerPhone, customer_name: customerName } =
			cell.row.original;

		switch (cell.column.id) {
			case ID:
				return (
					<BookingIdTableCell
						key={cell.column.id}
						notes={<Notes cell={cell} />}
						isHovered={isHovered}
						cell={cell}
					/>
				);

			case REASON:
				return <ReasonTableCell key={cell.column.id} cell={cell} />;

			case AMOUNT:
				return <CompensationAmountTableCell key={cell.column.id} cell={cell} />;

			case CUSTOMER:
				return (
					<TableCell key={cell.column.id}>
						{customerName}
						<br /> {customerPhone}
					</TableCell>
				);

			case COMPANY:
				return <CompanyTableCell key={cell.column.id} cell={cell} />;

			case VALIDATED_BY:
				return <ValidatedByTableCell key={cell.column.id} cell={cell} />;

			case STATUS:
				return <StatusTableCell key={cell.column.id} cell={cell} />;

			case ACTIONS:
				return <ActionsMenuTableCell key={cell.column.id} cell={cell} />;

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			key={row.id}
			className={isHovered ? classes.hoverRow : ''}
			ref={ref}
			{...row.getRowProps()}
		>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	columnsAccessor: object.isRequired,
	row: object.isRequired,
	onMouseLeave: func,
	onMouseEnter: func,
	isHovered: bool,
	ref: any,
};
