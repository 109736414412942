import React from 'react';
import { object, bool, string } from 'prop-types';
import { Typography as MuiTypography } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';

// Import components
import { InputError } from 'components/elements';

// Import styles
import { useStyles } from './styles';

// Import helpers
import { DEFAULT_SLASH_DATE_FORMAT } from 'helpers';

const DatePickerFieldAdapter = ({
	input,
	meta,
	isLabel = false,
	pickerFormat = DEFAULT_SLASH_DATE_FORMAT,
	...rest
}) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			{isLabel && (
				<MuiTypography component="span" className={classes.label}>
					{rest.label}
				</MuiTypography>
			)}
			<DatePicker {...input} {...rest} format={pickerFormat} />
			<InputError meta={meta} />
		</div>
	);
};

DatePickerFieldAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired,
	isDisabled: bool,
	pickerFormat: string,
	isLabel: bool,
};

export default DatePickerFieldAdapter;
