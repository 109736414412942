import React from 'react';
import { string } from 'prop-types';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Field } from 'react-final-form';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
} from '@material-ui/core';

// Import utilities
import { useMapLocation } from 'components/utilities';

// Import components
import { TerminalField } from './components';
import {
	LocationAutocompleteFieldAdapter,
	GoogleMapsScriptLoader,
} from 'components/elements';

// Import styles
import { useGoogleMapsStyles } from 'theme/styles/googleMaps';

const Address = ({ section }) => {
	const classes = useGoogleMapsStyles();

	const {
		t,
		fieldDisabled,
		isAirportCheckboxDisabled,
		markerLocation,
		setAutocompleteValue,
		onChange,
		onDragEnd,
	} = useMapLocation({ section, includeIsAirport: true });

	return (
		<Card>
			<CardHeader title={t('offices.form.location.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3} alignItems="center">
					<Grid item md={8} xs={12}>
						<Field
							fullWidth
							component={LocationAutocompleteFieldAdapter}
							type="text"
							name={`${section}.address`}
							label={t('offices.form.location.address')}
							margin="dense"
							variant="outlined"
							setAutocompleteValue={setAutocompleteValue}
							onChange={onChange}
							placeholder={t('offices.form.location.address')}
							disabled={fieldDisabled()}
							required
						/>
					</Grid>
					<TerminalField
						isAirportCheckboxDisabled={isAirportCheckboxDisabled}
						fieldDisabled={fieldDisabled}
						section={section}
					/>
					<Grid item md={12} xs={12}>
						<GoogleMapsScriptLoader>
							<GoogleMap
								mapContainerClassName={classes.mapContainer}
								center={markerLocation}
								zoom={15}
							>
								<Marker
									position={markerLocation}
									draggable
									onDragEnd={onDragEnd}
								/>
							</GoogleMap>
						</GoogleMapsScriptLoader>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

Address.propTypes = {
	section: string.isRequired,
};

export default React.memo(Address);
