import React from 'react';
import { Box, Grid } from '@material-ui/core';

// Import store
import { fetchCampaignsFormResources, submitCampaignForm } from 'store/actions';
import { campaignsFormSelector } from 'store/selectors';

// Import components
import { CampaignFormFields } from './components';
import { FormRPC } from 'components/utilities';

// Import helpers and utils
import useCampaignsForm from './useCampaignsForm';
import { URLS } from 'components/routes';

// Import styles
import { useStyles } from './styles';

export const CampaignsForm = () => {
	const { campaignType } = useCampaignsForm();
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<FormRPC
				fetchFormResAction={fetchCampaignsFormResources}
				submitFormAction={submitCampaignForm}
				isCardLayout={false}
				goBackPath={URLS.campaignsUrl}
				store={campaignsFormSelector}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item xs={12} container spacing={4}>
							<CampaignFormFields campaignType={campaignType} />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</Box>
	);
};
