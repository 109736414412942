import React from 'react';
import { object } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import helpers and utils
import {
	replaceCarListWithCompanyUrl,
	replaceCompanyOfficesUrl,
	replaceCompanyUsersUrl,
	replaceEditCompanyUrl,
} from 'helpers';
import { useTranslations } from 'components/utilities';
import { useCell } from './useCell';

// Import components
import {
	ActiveStatusTableCell,
	ImageTableCell,
	LinkTableCell,
	LinkWithIconTableCell,
	NewActionsMenuTableCell,
} from 'components/elements';
import { CityTableCell } from '../CityTableCell';

// Import assets
import { CarsIcon, OfficeIcon, TwoUsersIcon } from 'assets/icons';

export const Cell = ({ cell, columnsAccessor }) => {
	const {
		i18n: { language: lang },
	} = useTranslations();

	const { actionMenuFields } = useCell(cell);

	const {
		IMAGE,
		USERS,
		OFFICES,
		COMPANY_NAME,
		CITY,
		AVAILABLE,
		STATUS,
		ACTIONS,
	} = columnsAccessor;

	const renderCell = (cell) => {
		const {
			active_offices_count: activeOfficesCount,
			active_users_count: activeUsersNumber,
			active_cars_count: activeCarsCount,
			active,
			uuid,
			name,
		} = cell.row.original;

		switch (cell.column.id) {
			case IMAGE:
				return (
					<ImageTableCell
						cell={cell}
						key={cell.column.id}
						imgUrl={cell.row.original.media}
					/>
				);

			case COMPANY_NAME:
				return (
					<LinkTableCell
						key={cell.column.id}
						cell={cell}
						to={replaceEditCompanyUrl(uuid)}
					>
						{name[lang]}
					</LinkTableCell>
				);

			case CITY:
				return <CityTableCell cell={cell} key={cell.column.id} />;

			// Link will be done after finalize new car availability
			case AVAILABLE:
				return (
					<LinkWithIconTableCell
						text={activeCarsCount}
						key={cell.column.id}
						icon={CarsIcon}
						cell={cell}
						to={replaceCarListWithCompanyUrl(uuid)}
					/>
				);

			case USERS:
				return (
					<LinkWithIconTableCell
						text={activeUsersNumber}
						key={cell.column.id}
						icon={TwoUsersIcon}
						cell={cell}
						to={replaceCompanyUsersUrl(uuid)}
					/>
				);

			case OFFICES:
				return (
					<LinkWithIconTableCell
						text={activeOfficesCount}
						key={cell.column.id}
						icon={OfficeIcon}
						cell={cell}
						to={replaceCompanyOfficesUrl(uuid)}
					/>
				);

			case STATUS:
				return (
					<ActiveStatusTableCell
						cell={cell}
						isActive={active}
						key={cell.column.id}
					/>
				);

			case ACTIONS:
				return (
					<NewActionsMenuTableCell
						cell={cell}
						key={cell.column.id}
						previewPopupFields={actionMenuFields}
					/>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return renderCell(cell);
};

Cell.propTypes = {
	cell: object.isRequired,
	columnsAccessor: object,
};
