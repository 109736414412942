// Import helpers
import { FIELD_TYPES } from 'helpers';

export const FAQ_FIELDS = [
	{
		type: FIELD_TYPES.TEXT,
		label: 'faq.form.fields.title_en',
		name: 'details.name.en',
		required: true,
		md: 6,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'faq.form.fields.title_ar',
		name: 'details.name.ar',
		required: true,
		md: 6,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'faq.form.fields.description_en',
		name: 'details.description.en',
		required: true,
		md: 6,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'faq.form.fields.description_ar',
		name: 'details.description.ar',
		required: true,
		md: 6,
	},
	{
		type: FIELD_TYPES.NUMBER,
		label: 'faq.form.fields.order',
		name: 'order',
		required: true,
		md: 6,
		min: 1,
	},
];
