import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { bool, element, func, oneOfType, string } from 'prop-types';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useFileUploader } from './useFileUploader';

// Import components
import { ButtonProgress } from 'components/elements/atoms';

// Import styles
import { useStyles } from './FileUploader.styles';

export const FileUploader = ({
	defaultButtonText = 'common.buttons.choose_file',
	onSuccessCallback,
	onErrorCallback,
	buttonClassName,
	buttonFullWidth = false,
	required = true,
	error,
	label,
	extraLabel,
	startIcon: StartIcon,
}) => {
	const {
		handleOnInputChange,
		handleOnButtonClick,
		hiddenFileInput,
		buttonText,
		isLoading,
	} = useFileUploader({
		onSuccessCallback,
		onErrorCallback,
		defaultButtonText,
	});

	const { t } = useTranslations();

	const classes = useStyles({ error });

	return (
		<Grid container spacing={1}>
			{label && (
				<Grid item xs={12}>
					<Typography className={classes.label} component="span">{`${t(
						label
					)} ${required ? '*' : ''}`}</Typography>
					{extraLabel && (
						<Typography component="span" className={classes.extraLabel}>
							({t(extraLabel)})
						</Typography>
					)}
				</Grid>
			)}
			<Grid item xs={12}>
				<ButtonProgress
					onClick={handleOnButtonClick}
					className={buttonClassName}
					circularProgressSize={24}
					isLoading={isLoading}
					disabled={isLoading}
					variant="contained"
					color="primary"
					fullWidth={buttonFullWidth}
					startIcon={StartIcon && <StartIcon className={classes.icon} />}
				>
					{t(buttonText)}
				</ButtonProgress>
				<input
					onChange={handleOnInputChange}
					style={{ display: 'none' }}
					ref={hiddenFileInput}
					type="file"
				/>
			</Grid>
			{error && (
				<Grid item xs={12}>
					<Typography className={classes.error}>{error}</Typography>
				</Grid>
			)}
		</Grid>
	);
};

FileUploader.propTypes = {
	onSuccessCallback: func.isRequired,
	defaultButtonText: string,
	buttonClassName: string,
	onErrorCallback: func,
	buttonFullWidth: bool,
	required: bool,
	label: string,
	error: string,
	startIcon: oneOfType([element, func]),
	extraLabel: string,
};
