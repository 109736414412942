import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';

// Import helpers and utils
import { useBookingStatusActionsProvider } from 'components/context';
import { URLS } from 'components/routes';
import {
	BOOKING_DETAILS_TABS,
	getTabIndexBookingDetails,
	replacePreviewBookingUrl,
} from 'helpers';

// Import store
import { printBooking } from 'store/actions';

export const usePreviewPopup = (cell, rowUuid) => {
	const { uuid } = cell.row.original;
	const history = useHistory();

	const bookingUuid = rowUuid || uuid;

	const {
		handleApproveCancellation,
		toggleAssignDriverDialog,
		isAssignDriverDialogOpen,
		handleCancel,
		handleAccept,
		canApproveCancellation,
		canAccept,
		canCancel,
	} = useBookingStatusActionsProvider();

	const [mutate, { isLoading: invoicePrintLoading }] = useMutation((data) =>
		printBooking(data)
	);

	const handleOnInvoicePrint = () => mutate({ bookingUuid });

	const getAddRatingUrl = () => {
		const addRatingRab = getTabIndexBookingDetails(
			BOOKING_DETAILS_TABS.RATINGS
		);
		return `${replacePreviewBookingUrl(bookingUuid)}?tab=${addRatingRab}`;
	};

	const addRatingUrl = getAddRatingUrl();

	const isDivider = canAccept || canCancel || canApproveCancellation;

	const goToTransferBookings = () => history.push(URLS.transferredBookingsUrl);
	const goToRatingsTab = () => history.push(addRatingUrl);

	return {
		handleApproveCancellation,
		handleOnInvoicePrint,
		handleCancel,
		handleAccept,
		toggleAssignDriverDialog,
		isAssignDriverDialogOpen,
		canApproveCancellation,
		canCancel,
		canAccept,
		goToTransferBookings,
		invoicePrintLoading,
		goToRatingsTab,
		bookingUuid,
		isDivider,
	};
};
