import React from 'react';

// Import helpers
import { columns, COLUMNS_ACCESSORS } from './columns';
import { BUTTONS } from './helpers';

// Import components
import { InnerNavbarElementsButtons } from 'components/elements';
import { TableBodyRow, Export, Filters } from './components';
import { TableRPC } from 'components/utilities';

// Import store
import { fetchAccountingValidationBookings } from 'store/actions';

export const AccountingValidation = () => (
	<TableRPC
		tableNavigation={
			<InnerNavbarElementsButtons buttons={BUTTONS} filterPath="type" />
		}
		title="nav.accounting_validation.compensations_and_refunds"
		fetchDataTableAction={fetchAccountingValidationBookings}
		columnsAccessor={COLUMNS_ACCESSORS}
		outerToolbarElements={<Export />}
		tableBodyRow={TableBodyRow}
		filtersBar={<Filters />}
		columns={columns}
	/>
);
