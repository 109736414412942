import React from 'react';
import { array, bool, func, string } from 'prop-types';
import { Dialog, DialogContent, Grid } from '@material-ui/core';

// Import components
import { ClosableDialogTitle } from 'components/elements';
import { Import, Export } from './components';

// Import utils
import { TableExportProvider } from 'components/context';
import { useTranslations } from 'components/utilities';

export const ExportAndImportDialog = ({
	toggle,
	isOpen,
	columns,
	fetchDataTableAction,
	title,
	importDataUrl,
}) => {
	const { t } = useTranslations();

	return (
		<Dialog open={isOpen} fullWidth onClose={toggle}>
			<ClosableDialogTitle title={t(title)} onClose={toggle} />
			<DialogContent>
				<Grid container spacing={2} justifyContent="flex-end">
					<Import importDataUrl={importDataUrl} />
					<TableExportProvider
						columns={columns}
						fetchDataTableAction={fetchDataTableAction}
						noPagination
					>
						<Export />
					</TableExportProvider>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

ExportAndImportDialog.propTypes = {
	toggle: func.isRequired,
	isOpen: bool.isRequired,
	columns: array.isRequired,
	fetchDataTableAction: func.isRequired,
	title: string.isRequired,
	importDataUrl: string.isRequired,
};
