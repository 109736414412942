import React from 'react';
import { Grid } from '@material-ui/core';

// Import utils
import useTableExport from 'components/elements/organisms/TableExport/useTableExport';
import { useTranslations } from 'components/utilities';

// Import components
import { ButtonProgress } from 'components/elements';

export const Export = () => {
	const { handleExport, isLoading } = useTableExport();

	const { t } = useTranslations();

	return (
		<Grid item>
			<ButtonProgress
				onClick={handleExport}
				isLoading={isLoading}
				disabled={isLoading}
				variant="contained"
				color="primary"
			>
				{t('common.buttons.export')}
			</ButtonProgress>
		</Grid>
	);
};
