import { useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';

// Import utils and helpers
import { useToggle, useTranslations } from 'components/utilities';
import { FIELD_TYPES } from 'helpers';
import { INITIAL_VALUES } from '../../helpers';

export const useFormContent = () => {
	const { t } = useTranslations();
	const { change } = useForm();
	const { values, submitting } = useFormState();
	const {
		extra_days,
		extra_days_price,
		car_damages_price,
		fuel_refill_price,
		extra_hours,
		extra_hours_price,
		violations_price,
		extra_kilometer,
		extra_kilometer_price,
		total,
	} = values;

	const { on: extraFieldsVisible, toggle: toggleExtraFields } = useToggle();
	const FILE_FIELD = {
		type: FIELD_TYPES.FILE_UPLOADER,
		name: 'file',
		label: t('return_page.document'),
		extraLabel: 'return_page.optional',
		handleOnFileUploader: (file) => change('file', file),
		required: false,
	};

	const SwitchHelperText = extraFieldsVisible
		? t('return_page.add_additional_rental_related_fees')
		: t('return_page.assign_this_booking_message');

	const resetExtraFieldsState = () => {
		// eslint-disable-next-line
		Object.keys(values).map((key) => {
			if (key !== 'file') {
				change(key, INITIAL_VALUES[key]);
			}
		});
	};
	const handleSwitchChange = (e) => {
		if (e.target.checked) {
			change('is_outstanding_amount', true);
		} else {
			resetExtraFieldsState();
		}
		toggleExtraFields();
	};

	const EXTRA_FIELDS = [
		{
			label: t('return_page.extra_days'),
			FIELDS: [
				{
					name: 'extra_days_price',
					placeholder: t('return_page.enter_amount'),
					type: FIELD_TYPES.NUMBER,
				},
				{
					name: 'extra_days',
					placeholder: t('return_page.enter_days'),
					type: FIELD_TYPES.NUMBER,
				},
			],
		},
		{
			label: t('return_page.extra_hours'),
			FIELDS: [
				{
					name: 'extra_hours_price',
					placeholder: t('return_page.enter_amount'),
					type: FIELD_TYPES.NUMBER,
				},
				{
					name: 'extra_hours',
					placeholder: t('return_page.enter_hours'),
					type: FIELD_TYPES.NUMBER,
				},
			],
		},
		{
			label: t('return_page.car_damage_price'),
			FIELDS: [
				{
					name: 'car_damages_price',
					placeholder: t('return_page.enter_amount'),
					type: FIELD_TYPES.NUMBER,
				},
			],
		},
		{
			label: t('return_page.fuel_refill'),
			FIELDS: [
				{
					name: 'fuel_refill_price',
					placeholder: t('return_page.enter_amount'),
					type: FIELD_TYPES.NUMBER,
				},
			],
		},
		{
			label: t('return_page.violations'),
			FIELDS: [
				{
					name: 'violations_price',
					placeholder: t('return_page.enter_amount'),
					type: FIELD_TYPES.NUMBER,
				},
			],
		},
		{
			label: t('return_page.extra_km'),
			FIELDS: [
				{
					name: 'extra_kilometer_price',
					placeholder: t('return_page.enter_amount'),
					type: FIELD_TYPES.NUMBER,
				},
				{
					name: 'extra_kilometer',
					placeholder: t('return_page.enter_km'),
					type: FIELD_TYPES.NUMBER,
				},
			],
		},
	];

	const TOTAL_FIELD = {
		type: FIELD_TYPES.NUMBER,
		name: 'total',
		label: t('return_page.total_additional_amount_charged'),
	};

	const getMultiplicationValues = (field1, field2) => {
		if (field1 && field2) {
			return field1 * field2;
		} else {
			return 0;
		}
	};

	const getTotal = () => {
		const total =
			Number(fuel_refill_price) +
			Number(violations_price) +
			Number(car_damages_price) +
			getMultiplicationValues(extra_days, extra_days_price) +
			getMultiplicationValues(extra_hours, extra_hours_price) +
			getMultiplicationValues(extra_kilometer, extra_kilometer_price);

		return total;
	};

	const isCloseBtnDisabled = (extraFieldsVisible && !total) || submitting;

	useEffect(() => {
		const total = getTotal();
		change('total', total);
		// eslint-disable-next-line
	}, [values]);

	return {
		FILE_FIELD,
		EXTRA_FIELDS,
		TOTAL_FIELD,
		extraFieldsVisible,
		handleSwitchChange,
		isCloseBtnDisabled,
		SwitchHelperText,
		submitting,
	};
};
