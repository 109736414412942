export const COLUMNS_ACCESSORS = {
	IMAGE: 'image',
	CITY: 'city',
	AVAILABLE: 'available',
	COMPANY_NAME: 'company_name',
	OFFICES: 'offices',
	USERS: 'users',
	STATUS: 'status',
	ACTIONS: 'actions',
};

export const columns = [
	{
		Header: 'common.fields.logo',
		accessor: COLUMNS_ACCESSORS.IMAGE,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.name',
		accessor: COLUMNS_ACCESSORS.COMPANY_NAME,
		disableSortBy: true,
	},
	{
		Header: 'company.table.headers.contact_person',
		accessor: 'contact_person',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.city',
		accessor: COLUMNS_ACCESSORS.CITY,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.available',
		accessor: COLUMNS_ACCESSORS.AVAILABLE,
		disableSortBy: true,
	},
	{
		Header: 'company.table.headers.offices',
		accessor: COLUMNS_ACCESSORS.OFFICES,
		disableSortBy: true,
	},
	{
		Header: 'company.table.headers.users',
		accessor: COLUMNS_ACCESSORS.USERS,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.status',
		accessor: COLUMNS_ACCESSORS.STATUS,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];
