import React from 'react';
import { Button, Grid, Tooltip, Link } from '@material-ui/core';
import { bool, func, object, oneOfType } from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// Import components
import { IconButton, ValidationActionModal } from 'components/elements';

// Import assets
import { HistoryIcon, WalletIcon } from 'assets/icons';

// Import utils
import { useTranslations } from 'components/utilities';
import { useExtraActions } from './useExtraActions';

// Import styles
import { useStyles } from './ExtraActions.styles';

export const ExtraActions = ({
	toggleSubscriptionsHistory = () => {},
	toggleCompensationDialog = () => {},
	toggleBookingHistory = () => {},
	extraActions = false,
	cell = {},
	isHovered,
}) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const {
		bookingHistory,
		subscriptionHistory,
		compensation,
		validation,
		urgentMark,
		editAction,
		editPath,
	} = extraActions || {};

	const { handleOnOpenCompensationModal, toggleMarkUrgent } = useExtraActions({
		toggleCompensationDialog,
		cell,
	});

	return (
		<Grid container alignItems="center" spacing={2} wrap="nowrap">
			{bookingHistory && (
				<Grid item>
					<Tooltip title={t('bookings.booking_history.title')}>
						<span>
							<IconButton
								onClick={toggleBookingHistory}
								className={classes.button}
							>
								<HistoryIcon className={classes.icon} />
							</IconButton>
						</span>
					</Tooltip>
				</Grid>
			)}

			{subscriptionHistory && (
				<Grid item>
					<Tooltip title={t('common.buttons.subscription_history')}>
						<span>
							<IconButton
								onClick={toggleSubscriptionsHistory}
								className={classes.button}
							>
								<WalletIcon className={classes.icon} />
							</IconButton>
						</span>
					</Tooltip>
				</Grid>
			)}

			{compensation && (
				<Grid item>
					<Button
						variant="contained"
						color="primary"
						onClick={handleOnOpenCompensationModal}
					>
						{t('common.buttons.fill_info')}
					</Button>
				</Grid>
			)}

			{validation && (
				<Grid item>
					<ValidationActionModal cell={cell} isHovered={isHovered} />
				</Grid>
			)}

			{urgentMark && (
				<Grid item>
					<Button
						variant="contained"
						color="primary"
						onClick={toggleMarkUrgent}
					>
						{t('common.buttons.mark')}
					</Button>
				</Grid>
			)}

			{editAction && (
				<Grid item>
					<Link
						component={RouterLink}
						color="primary"
						to={editPath}
						className={classes.linkAsButton}
					>
						{t('common.buttons.edit')}
					</Link>
				</Grid>
			)}
		</Grid>
	);
};

ExtraActions.propTypes = {
	toggleSubscriptionsHistory: func,
	toggleBookingHistory: func,
	extraActions: oneOfType([object, bool]),
	toggleCompensationDialog: func,
	toggleUrgentMarkDialog: func,
	isHovered: bool,
	cell: object,
};
