import React from 'react';
import { Grid, MenuItem, Checkbox, CircularProgress } from '@material-ui/core';
import { bool, func, string } from 'prop-types';
import { Field } from 'react-final-form';

// Import components
import {
	MultiselectFiledAdapter,
	CheckboxFieldAdapter,
	Alert,
} from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';
import useTerminalField from './useTerminalField';

const TerminalField = ({
	section,
	fieldDisabled,
	isAirportCheckboxDisabled,
}) => {
	const { t } = useTranslations();

	const {
		getTerminalLabel,
		allTerminals,
		terminals,
		submitting,
		isFetching,
		isError,
		is_airport,
	} = useTerminalField();

	return (
		<>
			<Grid item md={4} xs={12}>
				<Field
					type="checkbox"
					component={CheckboxFieldAdapter}
					name={`${section}.is_airport`}
					disabled={fieldDisabled() || isAirportCheckboxDisabled}
					labelText={t('offices.form.location.is_airport')}
					color="primary"
				/>
			</Grid>
			{is_airport && (
				<Grid item md={12} xs={12}>
					{isFetching ? (
						<CircularProgress />
					) : !isError ? (
						<Field
							component={MultiselectFiledAdapter}
							type="select"
							label={t('kiosk.management.form.terminals.title')}
							name={`${section}.airport.terminals`}
							margin="dense"
							variant="outlined"
							disabled={submitting}
							format={(value) => (Array.isArray(value) ? value : [])}
							labelextractor={getTerminalLabel(allTerminals)}
						>
							{allTerminals.map(({ uuid, name }) => (
								<MenuItem key={uuid} value={uuid}>
									<Checkbox
										color="primary"
										checked={terminals.includes(uuid)}
									/>
									{name}
								</MenuItem>
							))}
						</Field>
					) : (
						<Alert title={t('common.messages.error_message')} />
					)}
				</Grid>
			)}
		</>
	);
};

TerminalField.propTypes = {
	section: string,
	isAirportCheckboxDisabled: bool,
	fieldDisabled: func,
};

export default TerminalField;
