import React from 'react';
import { object, string } from 'prop-types';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

// Import styles
import { useStyles } from './TextFieldAdapter.styles';

export const TextFieldAdapter = ({
	input,
	meta,
	additionalText = '',
	placeholder = '',
	...rest
}) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const error = !!(meta.touched && (meta.error || meta.submitError));
	const errorText =
		meta.touched && (meta.error || meta.submitError)
			? t(meta.error) || meta.submitError
			: '';

	const helperText = error && errorText ? errorText : additionalText;

	return (
		<TextField
			error={error}
			helperText={helperText}
			placeholder={placeholder}
			{...input}
			{...rest}
			className={classes.root}
			InputLabelProps={{
				className: classes.label,
			}}
		/>
	);
};

TextFieldAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired,
	additionalText: string,
	placeholder: string,
};
