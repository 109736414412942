import { useState } from 'react';
import { useFormState } from 'react-final-form';
import moment from 'moment';

// Import utils and helpers
import { useToggle } from 'components/utilities';
import { ENDPOINTS, YMD_SLASH_FORMAT } from 'helpers';
import services from 'services/services';

export const useFormContent = ({ booking }) => {
	const [surchargePrice, setSurchargePrice] = useState('');
	const [loading, setLoading] = useState(false);
	const { uuid: bookingUuid } = booking;
	const { on: isConfirmationDialogOpen, toggle: toggleConfirmationDialog } =
		useToggle(false);

	const {
		values: { date },
	} = useFormState();

	const btnDisabled = !date || loading;

	const onConfirmationClick = () => {
		setLoading(true);
		const dateParam = moment(date).format(YMD_SLASH_FORMAT);
		services
			.get(
				`${ENDPOINTS.RETURN_PAGE.EXTEND_CONTRACT({
					bookingUuid,
				})}?drop_off_date=${dateParam}`
			)
			.then((resp) => {
				setSurchargePrice(String(resp.data.surcharge_price));
				setLoading(false);
				toggleConfirmationDialog();
			});
	};

	return {
		isConfirmationDialogOpen,
		btnDisabled,
		toggleConfirmationDialog,
		onConfirmationClick,
		surchargePrice,
		loading,
	};
};
