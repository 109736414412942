import React, { useEffect } from 'react';
import { object, string } from 'prop-types';
import { Field, useFormState, useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { DETAILS_FIELDS } from './helpers';
import useCompanyCars from './useCompanyCars';

const SpecialOfferDetails = ({ className, classes, ...rest }) => {
	const { t } = useTranslations();

	const { companyList, isLoaded } = useSelector(
		(store) => store.specialOffer.form
	);

	const { submitting, values, modified } = useFormState();

	const { change } = useForm();

	const { data: companyCars, isFetching: isCompanyCarsLoading } =
		useCompanyCars(values?.company_uuid);

	useEffect(() => {
		if (modified.company_uuid) {
			change('vehicle_uuids', []);
		}
		//eslint-disable-next-line
	}, [values.company_uuid]);

	return (
		<Card className={className} {...rest}>
			<CardHeader title={t('special_offer.form.details.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{DETAILS_FIELDS({
						isLoaded,
						companyList,
						isCompanyCarsLoading,
						companyCars,
						classes,
					}).map(
						({
							name,
							label,
							type,
							component,
							md,
							xs,
							labelExtractor,
							children,
						}) => (
							<Grid key={name} item md={md} xs={xs}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									{type === 'select' ? (
										<Field
											fullWidth
											component={component}
											type={type}
											name={name}
											label={t(label)}
											margin="dense"
											variant="outlined"
											disabled={submitting}
											required
											labelextractor={labelExtractor} // No labelExtractor because DOM error
											/* eslint-disable-next-line react/no-children-prop */
											children={children}
										/>
									) : type === 'date' ? ( // because DOM error
										<Field
											fullWidth
											component={component}
											type="text"
											name={name}
											label={t(label)}
											margin="dense"
											inputVariant="outlined"
											disabled={submitting}
											required
											inputProps={{ min: 0 }}
										/>
									) : (
										<Field
											fullWidth
											component={component}
											type={type}
											name={name}
											label={t(label)}
											margin="dense"
											variant="outlined"
											disabled={submitting}
											required
											inputProps={{ min: 0 }}
										/>
									)}
								</MuiPickersUtilsProvider>
							</Grid>
						)
					)}
				</Grid>
			</CardContent>
		</Card>
	);
};

SpecialOfferDetails.propTypes = {
	className: string,
	classes: object,
};

export default SpecialOfferDetails;
