export const columns = [
	{
		Header: 'office_car_uuid',
		accessor: 'office_car_uuid',
		disableSortBy: true,
	},
	{
		Header: 'company_name',
		accessor: 'company_name',
		disableSortBy: true,
	},
	{
		Header: 'office_uuid',
		accessor: 'office_uuid',
		disableSortBy: true,
	},
	{
		Header: 'office_name',
		accessor: 'office_name',
		disableSortBy: true,
	},
	{
		Header: 'car_name',
		accessor: 'car_name',
		disableSortBy: true,
	},
	{
		Header: 'is_active',
		accessor: 'is_active',
		disableSortBy: true,
	},
	{
		Header: 'available_quantity',
		accessor: 'available_quantity',
		disableSortBy: true,
	},
	{
		Header: 'daily_rate',
		accessor: 'daily_rate',
		disableSortBy: true,
	},
	{
		Header: 'daily_discounted_rate',
		accessor: 'daily_discounted_rate',
		disableSortBy: true,
	},
	{
		Header: 'weekly_rate',
		accessor: 'weekly_rate',
		disableSortBy: true,
	},
	{
		Header: 'weekly_discounted_rate',
		accessor: 'weekly_discounted_rate',
		disableSortBy: true,
	},
	{
		Header: 'monthly_rate',
		accessor: 'monthly_rate',
		disableSortBy: true,
	},
	{
		Header: 'monthly_discounted_rate',
		accessor: 'monthly_discounted_rate',
		disableSortBy: true,
	},

	{
		Header: 'activate_subscription_model',
		accessor: 'activate_subscription_model',
		disableSortBy: true,
		valueExtractor: ({ activate_subscription_model }) =>
			Boolean(activate_subscription_model),
	},
	{
		Header: 'show_car_in_subscription_list_only',
		accessor: 'show_car_in_subscription_list_only',
		disableSortBy: true,
		valueExtractor: ({ show_car_in_subscription_list_only }) =>
			Boolean(show_car_in_subscription_list_only),
	},
	{
		Header: '3_months_rate',
		accessor: '3_months_rate',
		disableSortBy: true,
	},
	{
		Header: '6_months_rate',
		accessor: '6_months_rate',
		disableSortBy: true,
	},
	{
		Header: '9_months_rate',
		accessor: '9_months_rate',
		disableSortBy: true,
	},
	{
		Header: '12_months_rate',
		accessor: '12_months_rate',
		disableSortBy: true,
	},
	{
		Header: 'daily_before_markup',
		accessor: 'daily_before_markup',
		disableSortBy: true,
	},
	{
		Header: 'daily_after_markup',
		accessor: 'daily_after_markup',
		disableSortBy: true,
	},
	{
		Header: 'weekly_before_markup',
		accessor: 'weekly_before_markup',
		disableSortBy: true,
	},
	{
		Header: 'weekly_after_markup',
		accessor: 'weekly_after_markup',
		disableSortBy: true,
	},
	{
		Header: 'monthly_before_markup',
		accessor: 'monthly_before_markup',
		disableSortBy: true,
	},
	{
		Header: 'monthly_after_markup',
		accessor: 'monthly_after_markup',
		disableSortBy: true,
	},
];
