// Import helpers
import {
	ACTION_MENU_POPUP_FIELD_TYPES,
	BOOKING_CANCELLATION_STATES,
	CHIP_COLORS,
} from 'helpers';

const {
	TRANSACTIONS_HISTORY,
	TRANSFER_BOOKINGS,
	BOOKINGS_HISTORY,
	ADD_RATING,
	DOWNLOAD_INVOICE,
	ACCEPT_BOOKING,
	CANCEL_BOOKING,
} = ACTION_MENU_POPUP_FIELD_TYPES;

const { REVIEW, APPROVED, REJECTED, COMPENSATED } = BOOKING_CANCELLATION_STATES;

export const actionMenuFields = [
	{ type: ACCEPT_BOOKING },
	{ type: CANCEL_BOOKING },
	{ type: TRANSFER_BOOKINGS },
	{ type: BOOKINGS_HISTORY },
	{ type: TRANSACTIONS_HISTORY },
	{ type: ADD_RATING },
	{ type: DOWNLOAD_INVOICE },
];

export const cancelStatusList = [
	{
		label: 'common.common_states.review',
		color: CHIP_COLORS.WARNING,
		status: REVIEW,
	},
	{
		label: 'common.common_states.approved',
		color: CHIP_COLORS.SUCCESS,
		status: APPROVED,
	},
	{
		label: 'common.common_states.rejected',
		color: CHIP_COLORS.ERROR,
		status: REJECTED,
	},
	{
		label: 'common.common_states.compensated',
		color: CHIP_COLORS.INFO,
		status: COMPENSATED,
	},
];
