// ********************  table  ********************

export const fetchOfficesUrl = (queryParams, itemId) =>
	itemId
		? `/v2/supplier/business/company/${itemId}/office/list${queryParams}`
		: `v2/supplier/business/manager/offices${queryParams}`;

export const fetchCarsPricesUrl = (companyUuid) =>
	`/v2/supplier/business/company/${companyUuid}/car-prices`;

export const updateCarsPricesUrl = (companyUuid) =>
	`/v2/supplier/business/company/${companyUuid}/car-prices/import`;

export const updateActiveOfficeUrl = (id, isActive) =>
	`/v2/supplier/business/office/${id}/${isActive ? 'deactivate' : 'activate'}`;

export const deleteOfficeUrl = ({ officeUuid }) =>
	`/v2/supplier/business/office/${officeUuid}/remove`;

// ********************  form  ********************

export const businessOfficeDeliveryPricesUrl = `/v2/supplier/business/office/delivery-prices`;
export const businessOfficeDeliveryDistancesUrl = `/v2/supplier/business/office/delivery-distances`;

export const showBusinessOfficeUrl = (itemId) =>
	`/v2/supplier/business/office/${itemId}/show`;

export const submitOfficeFormUrl = (itemId, isEdit) =>
	`/v2/supplier/business/office/${isEdit ? `${itemId}/update` : 'create'}`;
