// Import utils and helpers
import { AddCarIcon, CarIcon } from 'assets/icons';
import {
	ACTION_MENU_POPUP_FIELD_TYPES,
	replaceAddCarUrl,
	replaceCarsUrl,
	replaceEditOfficeUrl,
} from 'helpers';

// Import store
import { updateActiveOffice } from 'store/actions';

const { ACTIVE, EDIT, REDIRECT } = ACTION_MENU_POPUP_FIELD_TYPES;

export const useCell = (cell) => {
	const { active: activeState, uuid: id } = cell.row.original;

	const editViewUrl = replaceEditOfficeUrl(id);

	const activeAction = () => updateActiveOffice({ isActive: activeState, id });

	const actionMenuFields = [
		{
			type: ACTIVE,
			isDivider: true,
			activeAction,
			activeState,
		},
		{ type: EDIT, editViewUrl, editText: 'common.buttons.edit_office' },
		{
			type: REDIRECT,
			redirectText: 'common.buttons.office_car_list',
			redirectUrl: replaceCarsUrl(id),
			redirectIcon: CarIcon,
			isDivider: true,
		},
		{
			type: REDIRECT,
			redirectText: 'common.buttons.add_car',
			redirectUrl: replaceAddCarUrl(id),
			redirectIcon: AddCarIcon,
		},
	];

	return {
		actionMenuFields,
		editViewUrl,
	};
};
