import React from 'react';
import { useLocation } from 'react-router-dom';
import { TableCell } from '@material-ui/core';
import { bool, object } from 'prop-types';

// Import helpers and utils
import { useBookingStatusActionsProvider } from 'components/context';
import { COLUMNS_ACCESSORS } from '../../../../BookingsList/columns';
import { useTranslations } from 'components/utilities';
import { URLS } from 'components/routes';
import { BOOKING_STATES } from 'helpers';
import { useCell } from './useCell';

// Import components
import {
	ExtendedAtTableCell,
	OfficeNameTableCell,
	CreatedAtTableCell,
} from 'views/Bookings/components';
import {
	DeliveryTimeAndLocationTableCell,
	StateWithActionsTableCell,
	SettlementStatusTableCell,
	DeliveryStatusTableCell,
	AssignedDriverTableCell,
	NewActionsMenuTableCell,
	RatingReasonTableCell,
	CompensatedTableCell,
	BookingIdTableCell,
	AssignDriverDialog,
	PickDateTableCell,
	DropDateTableCell,
	ApprovedTableCell,
	CompanyTableCell,
	PeriodTableCell,
	Notes,
} from 'components/elements';

const {
	SETTLEMENT_STATUS,
	DELIVERY_STATUS,
	ASSIGNED_DRIVER,
	PAYMENT_METHOD,
	CUSTOMER_NAME,
	RATING_REASON,
	OFFICE_NAME,
	COMPENSATED,
	EXTENDED_AT,
	CREATED_AT,
	DROP_DATE,
	PICK_DATE,
	TRANSFER,
	DURATION,
	APPROVED,
	ACTIONS,
	COMPANY,
	STATUS,
	PERIOD,
	ID,
} = COLUMNS_ACCESSORS;

export const Cell = ({ isHovered, cell }) => {
	const { pathname } = useLocation();
	const { t } = useTranslations();

	const { getActionMenuFields } = useCell();

	const {
		customer_phone: customerPhone,
		response_time: responseTime,
		customer_name: customerName,
		uuid: bookingUuid,
		payment,
		state,
		payment_link_uuid: paymentLinkUuid,
	} = cell.row.original;

	const isBookingView = pathname === URLS.bookingsUrl;

	const { SUSPEND, PAYMENT_PENDING } = BOOKING_STATES;

	const showPaymentCancelOption =
		state === SUSPEND || state === PAYMENT_PENDING;

	const { toggleAssignDriverDialog, isAssignDriverDialogOpen } =
		useBookingStatusActionsProvider();

	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		switch (cell.column.id) {
			case ACTIONS:
				return (
					<NewActionsMenuTableCell
						previewPopupFields={getActionMenuFields({
							showPaymentCancelOption,
							paymentLinkUuid,
						})}
						key={cell.column.id}
						cell={cell}
					/>
				);

			case OFFICE_NAME:
				return <OfficeNameTableCell key={cell.column.id} cell={cell} />;

			case CREATED_AT:
				return (
					<CreatedAtTableCell
						key={cell.column.id}
						showTime={false}
						cell={cell}
					/>
				);

			case EXTENDED_AT:
				return <ExtendedAtTableCell cell={cell} key={cell.column.id} />;

			case DROP_DATE:
				return <DropDateTableCell cell={cell} key={cell.column.id} />;

			case ID:
				return (
					<BookingIdTableCell
						key={cell.column.id}
						notes={<Notes cell={cell} />}
						isHovered={isHovered}
						cell={cell}
					/>
				);

			case PICK_DATE:
				return <PickDateTableCell key={cell.column.id} cell={cell} />;

			case STATUS:
				return (
					<StateWithActionsTableCell
						key={cell.column.id}
						cell={cell}
						isHovered={isHovered}
					/>
				);

			case DURATION:
				return (
					<TableCell key={cell.column.id}>
						{responseTime &&
							`${responseTime} ${t('bookings.table.common.minutes')}`}
					</TableCell>
				);

			case CUSTOMER_NAME:
				return (
					<TableCell key={cell.column.id}>
						{customerName}
						<br /> {customerPhone}
					</TableCell>
				);

			case APPROVED:
				return <ApprovedTableCell key={cell.column.id} cell={cell} />;

			case COMPENSATED:
				return <CompensatedTableCell key={cell.column.id} cell={cell} />;

			case RATING_REASON:
				return <RatingReasonTableCell key={cell.column.id} cell={cell} />;

			case SETTLEMENT_STATUS:
				return <SettlementStatusTableCell key={cell.column.id} cell={cell} />;

			case TRANSFER:
				return (
					<TableCell key={cell.column.id}>
						{cell.row.original.transfer.full_name}
					</TableCell>
				);

			case ASSIGNED_DRIVER:
				return <AssignedDriverTableCell key={cell.column.id} cell={cell} />;

			case DELIVERY_STATUS:
				return isBookingView ? (
					<DeliveryTimeAndLocationTableCell key={cell.column.id} cell={cell} />
				) : (
					<DeliveryStatusTableCell key={cell.column.id} cell={cell} />
				);

			case COMPANY:
				return <CompanyTableCell key={cell.column.id} cell={cell} />;

			case PERIOD:
				return <PeriodTableCell key={cell.column.id} cell={cell} />;

			case PAYMENT_METHOD:
				return <TableCell key={cell.column.id}>{payment.method}</TableCell>;

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<>
			<AssignDriverDialog
				toggle={toggleAssignDriverDialog}
				open={isAssignDriverDialogOpen}
				bookingUuid={bookingUuid}
			/>
			{renderCell(cell)}
		</>
	);
};

Cell.propTypes = {
	cell: object.isRequired,
	isHovered: bool,
};
