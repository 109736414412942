import { omit } from 'lodash';
import * as types from 'store/types';

// Import helpers
import {
	SUBSCRIPTION_MODEL_PERIODS,
	SUBSCRIPTION_MODEL_PERIODS_TEXT,
} from 'helpers';

export const carCatchError = ({ error, dispatch }) => {
	const defaultError = { message: '' };
	const response = error.response?.data ?? defaultError;
	dispatch({
		type: types.EDIT_CAR_FORM_FETCH_DETAILS_ERROR,
		payload: response,
	});
};

export const convertSubmitData = ({
	formValues,
	cars,
	offices,
	officesList,
	officeId,
	externalCars,
	choosenExternal,
	isEdit,
}) => {
	const {
		vehicle,
		details,
		prices,
		external,
		options,
		specification,
		subscription,
	} = formValues;

	const {
		free_kms,
		extra_km_nett_price,
		is_unlimited,
		unlimited_nett_price,
		is_insurance,
		insurance_prices,
		is_no_smoking_car,
		no_smoking_prices,
	} = options;

	const externalUuid = external.use_partner_car
		? getExternalCarUuid(externalCars, choosenExternal)
		: '';

	const vehicleUuid = { uuid: vehicle.uuid };

	const newOptions = {
		free_kms,
		extra_km_nett_price,
		is_unlimited,
		unlimited_nett_price,
		is_insurance,
		insurance_prices,
		is_no_smoking_car,
		no_smoking_prices,
	};

	const newRatingPrices = isEdit ? convertRatingPrices(prices) : prices;

	const newDetails = isEdit ? omit(details, ['active']) : details;

	const convertedSubscription = convertSubscriptionBeforeSubmit(subscription);

	const newEquipment = {
		navigation: specification.navigation,
	};

	const newSpecification = omit(specification, 'navigation');

	const newValues = {
		details: { ...newDetails },
		prices: newRatingPrices,
		options: { ...newOptions },
		subscription: convertedSubscription,
		equipment: newEquipment,
		specification: newSpecification,
	};

	const newCars = cars && cars.map((car) => ({ uuid: car }));

	const newOffices = converOffices({ offices, officesList });

	if (officeId) {
		return {
			...newValues,
			vehicle: vehicleUuid,
			office: { uuid: officeId },
			external: { ...external, partner_car_uuid: externalUuid },
		};
	} else if (!isEdit && !officeId) {
		return { ...newValues, vehicle: vehicleUuid, offices: newOffices };
	} else {
		return {
			...newValues,
			vehicle: { uuid: vehicle.global_uuid },
			cars: [...newCars],
		};
	}
};

const getExternalCarUuid = (externalCarsList, externalCar) =>
	externalCarsList.find((car) => (car.name.en || car.name.ar) === externalCar);

const converOffices = ({ offices, officesList }) => {
	if (offices?.length > 0) {
		const isAllOfficesCarsSelected = offices.length !== officesList.length;
		const newOffices = isAllOfficesCarsSelected
			? offices.map((office) => ({ uuid: office }))
			: [];
		return newOffices;
	}
};

const convertRatingPrices = (prices) => {
	const newPrices = Object.entries(prices).reduce((acc, next) => {
		const {
			days,
			nett_price,
			discount_nett_price,
			nett_price_before_markup,
			nett_price_after_markup,
		} = next[1];
		acc[next[0]] = {
			days,
			nett_price,
			discount_nett_price,
			nett_price_before_markup,
			nett_price_after_markup,
		};
		return acc;
	}, {});
	return newPrices;
};

const convertSubscriptionBeforeSubmit = (subscription) => {
	// Backend asked to not send subscription.prices.one with form data
	const filteredSubscription = omit(subscription, ['prices.one']);

	const { prices } = filteredSubscription || {};

	const numberKeys = Object.values(SUBSCRIPTION_MODEL_PERIODS_TEXT);

	const newPrices = Object.keys(prices).reduce((acc, key) => {
		const newKey = SUBSCRIPTION_MODEL_PERIODS[key.toUpperCase()];
		if (numberKeys.includes(key)) {
			acc[newKey] = prices[key];
		} else {
			acc[key] = prices[key];
		}
		return acc;
	}, {});

	return { ...filteredSubscription, prices: newPrices };
};

export const convertCarInfo = (carInfo) => {
	const equipment = carInfo.equipment;
	const specification = carInfo.specification || {};
	const newSpecification = { ...specification, ...equipment };
	const newCarInfo = omit(carInfo, 'equipment');

	return { ...newCarInfo, specification: newSpecification };
};

export const convertSubscriptionPrices = (carInfo) => {
	const subscriptionPrices = carInfo.subscription.prices;

	return Object.entries(SUBSCRIPTION_MODEL_PERIODS_TEXT).reduce(
		(acc, [numericPeriod, textPeriod], index) => {
			const newKey = {
				[textPeriod]: {
					...Object.values(subscriptionPrices)[index],
					period: numericPeriod,
				},
			};
			return { ...acc, ...newKey };
		},
		{}
	);
};

export const convertCarEditApiErrors = (apiErrors) => {
	const convertedErrors = Object.keys(apiErrors).reduce((acc, key) => {
		const error = apiErrors[key];
		const convertedError = {
			[`carInfo.${key}`]: error,
		};
		return { ...acc, ...convertedError };
	}, {});
	return convertedErrors;
};

export const EMPTY_FIELDS = {
	carInfo: {
		details: {
			quantity: '',
			description: {
				ar: '',
				en: '',
			},
		},
		vehicle: {
			maker_name: {
				en: '',
				ar: '',
			},
			model_name: {
				en: '',
				ar: '',
			},
			year: null,
			uuid: '',
		},
		options: {
			is_unlimited: false,
			unlimited_nett_price: '',
			free_kms: '',
			extra_km_nett_price: '',
			is_insurance: false,
			is_no_smoking_car: false,
			insurance_prices: {
				daily: {
					nett_price: 0,
				},
				weekly: {
					nett_price: 0,
				},
				monthly: {
					nett_price: 0,
				},
			},
			no_smoking_prices: {
				daily: {
					nett_price: 0,
				},
				weekly: {
					nett_price: 0,
				},
				monthly: {
					nett_price: 0,
				},
			},
		},
		external: {
			car_codes: [],
			has_external: false,
			codes: [],
		},
		equipment: {
			navigation: false,
		},
		specification: {
			doors_number: '5',
			bluetooth: false,
			panorama: false,
			usb: false,
			rear_camera: false,
			sensors: false,
			car_multimedia: false,
			fuel_type: 'diesel',
		},
		prices: {
			daily: {
				days: 1,
				discount_nett_price: 0,
				discount_gross_price: 0,
			},
			weekly: {
				discount_nett_price: 0,
				discount_gross_price: 0,
			},
			monthly: {
				discount_nett_price: 0,
				discount_gross_price: 0,
			},
			annual: {
				discount_nett_price: 0,
				discount_gross_price: 0,
			},
		},
		subscription: {
			is_enabled: false,
			prices: {
				one: {
					nett_price: 0,
					period: SUBSCRIPTION_MODEL_PERIODS.ONE,
				},
				three: {
					nett_price: 0,
					period: SUBSCRIPTION_MODEL_PERIODS.THREE,
				},
				six: {
					nett_price: 0,
					period: SUBSCRIPTION_MODEL_PERIODS.SIX,
				},
				nine: {
					nett_price: 0,
					period: SUBSCRIPTION_MODEL_PERIODS.NINE,
				},
				twelve: {
					nett_price: 0,
					period: SUBSCRIPTION_MODEL_PERIODS.TWELVE,
				},
			},
		},
	},
	prices: {
		annual: {
			from: 31,
			to: 360,
			average_nett_price: '',
			lowest_nett_price: '',
		},
		monthly: {
			from: 8,
			to: 30,
			average_nett_price: '',
			lowest_nett_price: '',
		},
		weekly: {
			from: 2,
			to: 7,
			average_nett_price: '',
			lowest_nett_price: '',
		},
		daily: {
			from: 1,
			to: 1,
			average_nett_price: '',
			lowest_nett_price: '',
		},
	},
	externalCars: [],
	cars: [],
	offices: [],
};
