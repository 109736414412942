import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiFormHelperText-contained': {
			margin: '12px 0',
			fontSize: '14px',
		},
	},
	label: {
		color: theme.palette.text[1],
	},
}));
