import services from 'services/services';
import {
	fetchOfficesUrl,
	updateActiveOfficeUrl,
	fetchCarsPricesUrl,
	deleteOfficeUrl,
} from 'store/paths';

export const fetchOffices =
	({ options, queryParams, itemId, cancelToken }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			// Get response
			const { data } = await services.post(
				fetchOfficesUrl(queryParams, itemId),
				body,
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const fetchCarPrices =
	({ itemId, cancelToken }) =>
	async () => {
		try {
			const { data } = await services.post(
				fetchCarsPricesUrl(itemId),
				{},
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const updateActiveOffice = async ({ isActive, id }) =>
	await services.put(updateActiveOfficeUrl(id, isActive));

export const deleteOffice = async ({ officeUuid }) =>
	await services.delete(deleteOfficeUrl({ officeUuid }));
