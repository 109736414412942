import React from 'react';
import { Field } from 'react-final-form';
import { bool, func, number, oneOfType, string } from 'prop-types';
import { CircularProgress, Grid, MenuItem } from '@material-ui/core';

// Import components
import {
	SelectFieldAdapter,
	SelectFieldWithSearchAdapter,
} from 'components/elements';

// Import utilities
import { useCompanyOfficesFilter } from './useCompanyOfficesFilter';
import { useTranslations } from 'components/utilities';

export const CompanyOfficesFilter = ({
	label,
	name,
	xs = 3,
	md = 3,
	smallFieldStyle = false,
	withSearch = false,
	showEmptyLabel = false,
	icon = null,
}) => {
	const { t, i18n } = useTranslations();

	const { filteredOffices, offices, isFieldDisabled, isFetching } =
		useCompanyOfficesFilter(name);

	const lng = i18n.language;

	if (!offices) {
		return null;
	}

	return (
		<Grid item xs={xs} md={md}>
			{isFetching ? (
				<CircularProgress size={28} />
			) : (
				<Field
					fullWidth
					component={
						withSearch ? SelectFieldWithSearchAdapter : SelectFieldAdapter
					}
					label={t(label)}
					name={name}
					variant="outlined"
					margin="dense"
					disabled={isFieldDisabled}
					smallfieldstyle={smallFieldStyle && smallFieldStyle.toString()}
					emptyLabel={showEmptyLabel ? t('common.fields.office') : ''}
					icon={icon}
				>
					<MenuItem value="all">{t('common.all')}</MenuItem>
					{filteredOffices.map(({ uuid, name }) => (
						<MenuItem key={uuid} value={uuid}>
							{name[lng]}
						</MenuItem>
					))}
				</Field>
			)}
		</Grid>
	);
};

CompanyOfficesFilter.propTypes = {
	label: string.isRequired,
	name: string.isRequired,
	xs: oneOfType([number, string]),
	md: oneOfType([number, string]),
	smallFieldStyle: bool,
	withSearch: bool,
	showEmptyLabel: bool,
	disabled: bool,
	icon: oneOfType([bool, func]),
};
