import React from 'react';
import { bool, func, string } from 'prop-types';
import { useParams } from 'react-router-dom';

// Import components
import { ExportAndImportDialog } from 'components/elements';

// Import utils
import { useProfile } from 'components/utilities';
import { fetchCarPrices } from 'store/actions';

// Import helpers
import { columns } from './columns';
import { updateCarsPricesUrl } from 'store/paths';

export const EditPrices = ({ toggle, isOpen, outerCompanyUuid }) => {
	const { id: companyUuidParams } = useParams();
	const { companyUuid: companyUuidProfile, isAdmin } = useProfile();

	const companyUuid = isAdmin
		? outerCompanyUuid || companyUuidParams
		: companyUuidProfile;

	return (
		<ExportAndImportDialog
			toggle={toggle}
			isOpen={isOpen}
			columns={columns}
			fetchDataTableAction={(options) =>
				fetchCarPrices({ ...options, itemId: companyUuid })
			}
			title="offices.edit_prices_dialog.heading"
			importDataUrl={updateCarsPricesUrl(companyUuid)}
		/>
	);
};

EditPrices.propTypes = {
	toggle: func.isRequired,
	isOpen: bool.isRequired,
	outerCompanyUuid: string,
};
