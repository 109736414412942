export const COLUMNS_ACCESSORS = {
	ID: 'id',
	CUSTOMER: 'customer',
	COMPANY: 'company',
	PICK_DATE: 'pick_date',
	OFFICE_RATING: 'office_rating',
	CREATED_AT: 'created_at',
	ACTIONS: 'actions',
};

export const columns = [
	{
		Header: 'common.fields.id',
		accessor: COLUMNS_ACCESSORS.ID,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.customer',
		accessor: COLUMNS_ACCESSORS.CUSTOMER,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.company',
		accessor: COLUMNS_ACCESSORS.COMPANY,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.pick_date',
		accessor: COLUMNS_ACCESSORS.PICK_DATE,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.office_rating',
		accessor: COLUMNS_ACCESSORS.OFFICE_RATING,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];
