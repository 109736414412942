import React from 'react';
import { useForm } from 'react-final-form';
import { Button, Box } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { array, object } from 'prop-types';

const AllCarsButton = ({ classes, companyCars }) => {
	const { t } = useTranslations();
	const { change } = useForm();

	const handleClick = () => {
		change(
			'vehicle_uuids',
			companyCars.map((element) => element.vehicle_uuid)
		);
	};

	return (
		<Box className={classes.wrapper}>
			<Button
				className={classes.continueButton}
				color="primary"
				fullWidth
				size="large"
				variant="contained"
				onClick={handleClick}
			>
				{t('special_offer.form.details.allCars')}
			</Button>
		</Box>
	);
};

AllCarsButton.propTypes = {
	classes: object,
	companyCars: array,
};

export default AllCarsButton;
