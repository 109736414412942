import React from 'react';
import { Button } from '@material-ui/core';
import { string, object, bool } from 'prop-types';

// Import utils
import { useValidationActionModal } from './useValidationActionModal';

// Import components
import { ValidationModal } from './components';

// Import translations
import { useTranslations } from 'components/utilities';

export const ValidationActionModal = ({
	actionButtonTitle,
	isHovered,
	cell,
}) => {
	const { t } = useTranslations();

	const { handleCloseModal, handleOpenModal, isValidateButtonVisible, isOpen } =
		useValidationActionModal({ cell, isHovered });

	return (
		<>
			{isValidateButtonVisible && (
				<Button
					onClick={handleOpenModal}
					variant="contained"
					color="primary"
					size="small"
				>
					{actionButtonTitle || t('validation.table.action.validate')}
				</Button>
			)}
			<ValidationModal open={isOpen} onClose={handleCloseModal} />
		</>
	);
};

ValidationActionModal.propTypes = {
	actionButtonTitle: string,
	cell: object.isRequired,
	isHovered: bool,
};
